import React, {Component} from "react";
import {
    Card,
    CardBody,
    PaginationItem,
    PaginationLink,
    CardHeader,
    Form,
    Row,
    Col,
    Label,
    Input,
    Button, Modal
} from "reactstrap";
import {showListChannelUpload, searchChannelUpload} from '../../helpers/ServiceUtils/DataService'
import ChannelUpload from '../../components/TableItem/ChannelUpload'
import Loading from '../../components/Action/Loading'

class ChannelUploadTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pages: [],
            page: 0,
            modal_info: false,
            currentChannelId: '',
            username: '',
            passwd: '',
            recover: '',
            phone: '',
            loading: true,
            channelSearch:'',
            typeChild:'',
            type:-1,
            orderBy:'video_upload_end_time'
        }
        this.showDetail = this.showDetail.bind(this);
        this.showDataSearch = this.showDataSearch.bind(this);

    }

    showDetail(channel_id, username, passwd, recover, phone) {
        this.setState({
            currentChannelId: channel_id,
            username: username,
            passwd: passwd,
            recover: recover,
            phone: phone,
            modal_info: !this.state.modal_info

        });
    }

    componentDidMount() {
        this.showData(0,'video_upload_end_time');
    }

    showData = (page,orderBy) => {
        this.setState({
            loading: true
        })
        showListChannelUpload(page,orderBy)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== false) {
                    console.log(responseJson);
                    this.setState({
                        data: responseJson.channels,
                        pages: responseJson.pages,
                        page: page,
                        loading: false
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }
    showDataSearch = (page) => {
        this.setState({
            loading: true
        })
        //e.preventDefault();
        searchChannelUpload(page, this.state.channelSearch,this.state.typeChild,this.state.type)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== false) {
                    //console.log(responseJson);
                    this.setState({
                        data: responseJson.channels,
                        pages: responseJson.pages,
                        page: page,
                        loading: false
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }

    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
        if(target.name==="orderBy"){
            this.showData(0,value);
        }
    }
    getChannelCategory (){

    }
    render() {
        let stt = this.state.page * 50;
        const tableItem = this.state.data.map((item, index) => {
            return <ChannelUpload key={item.channel_id} stt={stt++}
                                  channel_id={item.channel_id} channel_title={item.channel_title}
                                  channel_action_status={item.channel_action_status}
                                  channel_upload_perday={item.channel_upload_perday}
                                  channel_upload_type={item.channel_upload_type}
                                  total_video_uploaded={item.total_video_uploaded}
                                  last_video_upload={item.last_video_upload}
                                  channel_upload_type_child={item.channel_upload_type_child}
                                  channel_computername={item.channel_computername}
                                  video_uploaded_today={item.video_uploaded_today}
                                  views={item.views}
                                  showPass={this.state.showPass}
            />;

        });
        const pageItem = this.state.pages.map((item, index) => {
            if (this.state.page === item) {
                return <PaginationItem key={index} active>
                    <PaginationLink tag="button" onClick={() => this.showData(item)}>{item}</PaginationLink>
                </PaginationItem>;
            } else {
                return <PaginationItem key={index}>
                    <PaginationLink tag="button" onClick={() => this.showData(item)}>{item}</PaginationLink>
                </PaginationItem>;
            }
        });




        return (
            <Card>
                <CardHeader>
                    <Form onSubmit={() => this.showDataSearch(0)}>
                        <div data-repeater-list="group-a">
                            <Row data-repeater-item>
                                <Col lg="3" className="form-group">
                                    <Label for="name">Channel ID/Channel Name</Label>
                                    <Input
                                        onChange={this.onHandleChange}
                                        type="text"
                                        id="name"
                                        name="channelSearch"
                                        defaultValue={this.state.channelSearch}
                                    />
                                </Col>
                                <Col lg="3" className="form-group">
                                    <Label for="email">Loại post </Label>
                                    <Input onChange={this.onHandleChange} type="email" name={'typeChild'} id="typeChild" defaultValue={this.state.typeChild}/>
                                </Col>
                                <Col lg="2" className="form-group">
                                    <Label for="subject">Loại Kênh</Label>
                                    <select className="form-control"
                                                       name={'type'}
                                                       onChange={this.onHandleChange} defaultValue={this.state.type}>
                                    <option value={-1}>Tất Cả</option>
                                    <option value={0}>Chờ</option>
                                    <option value={1}>Báo</option>
                                    <option value={2}>Nhạc</option>
                                    <option value={3}>Reup</option>
                                    <option value={4}>Tiktok</option>
                                    <option value={5}>Từ Máy</option>
                                </select>
                                </Col>
                                <Col lg="2" className="form-group">
                                    <Label for="subject">Xếp Theo</Label>
                                    <select className="form-control"
                                            name={'orderBy'}
                                            onChange={this.onHandleChange} >
                                        <option value={'video_upload_end_time'}>Thời gian post</option>
                                        <option value={'views'}>Lượt xem</option>
                                    </select>
                                </Col>
                                <Col lg="2" className="form-group align-self-center">
                                    <Button onClick={() => this.showDataSearch(0)} color="primary" className="mt-3"
                                            style={{width: "100%"}}>
                                        Tìm Kiếm
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Tên Kênh</th>
                                <th scope="col">Trạng Thái</th>
                                <th scope="col">Video Uploaded</th>
                                <th scope="col">Loại Kênh</th>
                                <th scope="col">Loại Post</th>
                                <th scope="col">Tên Máy</th>
                                <th scope="col">Videos</th>
                                <th scope="col">Views</th>
                                <th scope="col">Lần Post Cuối</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.loading ? <Loading/> : tableItem}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">
                            {pageItem}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    toggle={this.showDetail}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{"Kênh : " + this.state.currentChannelId}</h5>
                        <button
                            type="button"
                            onClick={() =>
                                this.setState({modal_center: false})
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Username
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.username}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Passwd
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.passwd}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Recover
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.recover}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Phone
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.phone}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}

export default ChannelUploadTable;
