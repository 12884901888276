import {UPDATE_CHANNELS_SUCCESS,UPDATE_CHANNELS, REMOVE_CHANNELS_SUCCESS, REMOVE_CHANNELS, INSERT_CHANNELS_SUCCESS,INSERT_CHANNELS, FILTER_CHANNELS,FETCH_CHANNELS_SUCCESS,FETCH_CHANNELS} from './actionTypes'
const initialState = {
    channelsubscribes :[],
    channelsubscribesMAX:[]
}
function ChannelSubscribeReducer(state=initialState,action){
    switch (action.type) {
        case FETCH_CHANNELS_SUCCESS:
            return {
                ...state,
                channelsubscribes: action.channelsubscribes,
                channelsubscribesMAX:action.channelsubscribes
            };
        case INSERT_CHANNELS_SUCCESS:
            const newData= [action.channelsubscribe].concat(state.channelsubscribesMAX);
            return {
                ...state,
                channelsubscribes: newData,
                channelsubscribesMAX:newData
            }
        case UPDATE_CHANNELS_SUCCESS:
            const newUpdateData=state.channelsubscribesMAX.map((item,index) =>{
                 return item.channel_subscribe_id==action.channelsubscribe.channel_subscribe_id?action.channelsubscribe:item;
            });
            return {
                ...state,
                channelsubscribes :newUpdateData,
                channelsubscribesMAX:newUpdateData
            }
        case REMOVE_CHANNELS_SUCCESS:
            const newRemoveData=state.channelsubscribesMAX.filter((item,index) =>{
                return item.channel_subscribe_id==action.channel_subscribe_id?false:true;
            });
            return {
                ...state,
                channelsubscribes :newRemoveData,
                channelsubscribesMAX:newRemoveData
            }
        case FILTER_CHANNELS:
            return {

            }
        default:
            return state;
    }
}
export default ChannelSubscribeReducer;
