import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';
import { loginViaWebService} from '../../../helpers/ServiceUtils/Auth';
//AUTH related methods
import { getFirebaseBackend } from '../../../helpers/authUtils';

const fireBaseBackend = getFirebaseBackend();
var md5 = require('md5');
function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(fireBaseBackend.loginUser, user.email, md5(user.password));
        yield put(loginSuccess(response));
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        //const response = yield call(fireBaseBackend.logout);
        //yield put(logoutUserSuccess("response"));
        yield put(logoutUserSuccess(sessionStorage.getItem('username')));
        yield sessionStorage.setItem('username',"");
        yield sessionStorage.setItem('token', "");
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* loginUserViaSSCService({ payload: { user, history } }) {
    try {
        const response = yield call(loginViaWebService, user.email, md5(user.password));
        const responseJson= yield response.json();

        if (responseJson.status == "success") {
            sessionStorage.setItem('username', user.email);
            sessionStorage.setItem('token', responseJson.token);
            yield put(loginSuccess(response));
            history.push('/dashboard');
        } else {
            yield put(apiError("Username | Passwd error"));
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUserViaSSCService(history) {
    try {
        yield put(logoutUserSuccess(sessionStorage.getItem('username')));
        yield sessionStorage.setItem('username',"");
        yield sessionStorage.setItem('token', "");
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }

}




/*
2 hàm loginUserViaSSCService và logoutUserViaSSCService sẽ thay thành
loginUser,logoutUser nếu chuyển thành dạng login theo dạng firebase
 */

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUserViaSSCService)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;
