import React from "react"
import {Button,Label} from 'reactstrap';
import {removeStream,updateStreamStatus} from '../../store/Playlist/ServiceApi'
import moment from 'moment'
class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            stt: this.props.stt,
            id: this.props.id,
            usename: this.props.usename,
            phone: this.props.phone,
            email: this.props.email,
            status_number: this.props.status_number,
            status: this.props.status,
            money: this.props.money
        }
        this.onClickRemoveAccount=this.onClickRemoveAccount.bind(this)
        this.onClickStream=this.onClickStream.bind(this)

    }
    componentDidMount() {
        //this.props.reloadViewVideo();
    }
    onClickRemoveAccount(){
        if(window.confirm("Bạn có chắc chắn xóa luồng này?")){
            removeStream(this.props.stream_id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.props.loadStreams();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    onClickStream(){
        if(window.confirm("Bạn có chắc stream luồng này?")){
            updateStreamStatus(0,"Chờ stream",this.props.stream_id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.props.loadStreams();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    render() {
        const minLeft=moment.unix(this.props.start_time).diff(moment(),"minutes")
        const minEndLeft=moment.unix(parseInt(this.props.start_time)+parseInt(this.props.end_time)*60).diff(moment(),"minutes")
        return <tr style={{height: 5}}>
            <td>{this.props.stt}</td>
            <td>{this.props.note}</td>
            <td>{(this.props.start_time==0||minLeft<0)?"none":("sau "+minLeft+" phút")}</td>
            <td>{(this.props.end_time==0||minEndLeft<0)?"none":(this.props.end_time+" phút")}</td>
            <td>{this.props.key_live}</td>
            <td>
                {this.props.full_hd==1?<Label size={'sm'} color={'primary'}>1080P</Label>:null}&nbsp;
                <Button size={'sm'} color={'danger'} onClick={this.onClickRemoveAccount}>Xóa</Button>&nbsp;
                <Button size={'sm'} color={'info'} onClick={this.props.showCurrentStream}>Sửa</Button>&nbsp;
                {this.props.status_number==-1?<Button size={'sm'} color={'primary'} onClick={this.onClickStream}>Stream Ngay</Button>:null}
                {this.props.status_number==0?<Button size={'sm'} color={'warning'} >Đang chờ stream</Button>:null}
            </td>
        </tr>;
    }
}
export default User;
