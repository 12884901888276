import React from "react"
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Button,
    Label,
    Badge, Progress, Col
} from 'reactstrap';
import moment from 'moment';
import constant from '../../constant';

class VideoUploadReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            stt: this.props.stt,
            video_upload_type_child: this.props.video_upload_type_child,
            video_upload_type: this.props.video_upload_type,
            wait_video: 0,
            uploaded_video: 0,
            channels_count: this.props.channels_count,
            total_view:this.props.total_view,
            setup:false
        }
        this.reloadData();
    }

    removeUploadVideo = () => {
        fetch("http://" + constant.pageUrl + "/backend/index.php/VideoUploadController/removeWaitVideoUpload/" + this.state.video_upload_type_child, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {

                } else {
                    window.location.reload();
                }
            }).catch((error) => {
            console.log(error);
        }).finally(() => {
            window.location.reload();
        });
    }
    reloadData = () => {
        fetch("http://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getReportDetail/" + this.state.video_upload_type_child, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "id=" + this.state.id + "&token=" + sessionStorage.getItem('token')
            , // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {
                } else {
                    this.setState({
                        wait_video: responseJson.wait_video,
                        uploaded_video: responseJson.uploaded_video
                    });
                }
            }).catch((error) => {
            console.log(error);
        });
    }


    insertUploadVideo = () => {
        this.setState({
            setup:true
        });
        fetch("http://" + constant.pageUrl + "/backend/index.php/VideoUploadController/insertVideoUpload", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "token=" + sessionStorage.getItem('token')+"&video_upload_type_child="+this.state.video_upload_type_child
            , // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {
                } else {

                }
            }).catch((error) => {
            console.log(error);
        }).finally(()=>{
            this.setState({
                setup:false
            });
            this.reloadData();
        });
    }


    setupVideo = () => {
        fetch("http://" + constant.pageUrl + "/backend/index.php/VideoUploadController/insertVideoSetup", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "token=" + sessionStorage.getItem('token')+"&key1="+this.state.key1+"&key2="+this.state.key2+"&key3="+this.state.key3+"&key4="+this.state.key4+
                "&description="+this.state.description+"&keyword="+this.state.keyword+"&video_upload_type_child="+this.state.video_upload_type_child
            , // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {
                } else {
                    this.setState({
                        primary: !this.state.primary
                    });
                }
            }).catch((error) => {
            console.log(error);
        });
    }


    togglePrimary = () => {
        fetch("http://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getVideoSetup", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "token=" + sessionStorage.getItem('token') + "&video_upload_type_child=" + this.state.video_upload_type_child, // <-- Post parameters
            //<-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== false) {
                    this.setState({
                        key1: responseJson.key1,
                        key2: responseJson.key2,
                        key3: responseJson.key3,
                        key4: responseJson.key4,
                        description: responseJson.description,
                        keyword: responseJson.keyword,
                        primary: !this.state.primary
                    });
                    console.log("primary " + this.state.primary);
                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? (target.checked ? 0 : 1) : target.value;
        this.setState({
            [name]: value,
        });
    }
    render() {
        if (!this.state.removeMode) {
            return <tr onClick={this.props.onClick}>
                <td>{this.props.stt}</td>
                <td>{this.state.video_upload_type_child}</td>
                <td>{this.state.wait_video}</td>
                <td>{this.state.uploaded_video}</td>
                <td>{this.state.channels_count}</td>
                <td>{this.state.total_view}</td>
            </tr>;

        } else {
            return "";
        }
    }
}
export default VideoUploadReport;
