import React, {Component} from "react";
import SettingMenu from "../../Shared/SettingMenu";
import {Link} from "react-router-dom";
import TableVideoInteractive from "../../../components/Table/TableVideoInteractive";
import CTActionVideoTable from "../../../components/Table/CTActionVideoTable";

import ActionChart from "../../../components/Action/ActionChart"
import {
    Row,
    Col,
    Card,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardBody
} from "reactstrap";

// import images
import user1 from "../../../assets/images/users/user-1.jpg";
import user2 from "../../../assets/images/users/user-2.jpg";
import user3 from "../../../assets/images/users/user-3.jpg";
import user4 from "../../../assets/images/users/user-4.jpg";
import user6 from "../../../assets/images/users/user-6.jpg";

import img3 from "../../../assets/images/small/img-3.jpg";
import img4 from "../../../assets/images/small/img-4.jpg";
import TableProduct from "../../../components/Table/TableProduct";

class ViewInteractive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folder_menu: false,
            tag_menu: false,
            more_menu: false,
            video: {
                video_sub_id: '',
                channel_id: ''
            }
        };
        this.toggleFolder = this.toggleFolder.bind(this);
        this.toggleTag = this.toggleTag.bind(this);
        this.toggleMore = this.toggleMore.bind(this);
        this.chageModeView = this.chageModeView.bind(this);
    }

    toggleFolder() {
        this.setState(prevState => ({
            folder_menu: !prevState.folder_menu
        }));
    }

    toggleTag() {
        this.setState(prevState => ({
            tag_menu: !prevState.tag_menu
        }));
    }

    toggleMore() {
        this.setState(prevState => ({
            more_menu: !prevState.more_menu
        }));
    }

    chageModeView(video) {
        //console.log(video);
        this.setState({
            video: video
        });
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Tăng Lượt xem tương tác</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="float-right d-none d-md-block">
                                <SettingMenu/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <TableVideoInteractive parent={this} type={0}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <ActionChart type={2} key={this.state.video.video_sub_id + "-week"} mode={'week'}
                                         video={this.state.video}/>
                        </Col>
                      <Col sm={6}>
                             <ActionChart type={2} key={this.state.video.video_sub_id + "-day"} mode={'day'}
                                     video={this.state.video}/>
                      </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <CTActionVideoTable key={this.state.video.video_sub_id} video={this.state.video}
                                                realtime={true} type={2}/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default ViewInteractive;
