import React,{Component} from "react";
import {Card, CardBody, Col, Label} from 'reactstrap';
import BarApexChart from "../../pages/AllCharts/apex/barchart";
import {getSubscribeRunData24H,getSubscribeRunDataWeek} from '../../store/channelsub/ApiService'
import {getTimeBoosterData24H,getTimeBoosterDataWeek} from '../../store/viewsub/ApiService'
import {getViewBoosterData24H,getViewBoosterDataWeek} from '../../store/viewinteractive/ApiService'
export default class ActionChart extends Component{
    constructor(props) {
        super(props);
        this.state={
            series: [],
            categories:[],
            key:0
        }
        this.refreshData=this.refreshData.bind(this);
    }


    refreshData(){
        let req ;
        if(this.props.type==0){
            if(this.props.mode=="week"){
                req= getTimeBoosterDataWeek(this.props.video.video_sub_id);
            }else{
                req= getTimeBoosterData24H(this.props.video.video_sub_id);
            }
        }else if(this.props.type==1){
            if(this.props.mode=="week"){
                req= getSubscribeRunDataWeek(this.props.video.channel_id);
            }else{
                req= getSubscribeRunData24H(this.props.video.channel_id);
            }
        }else{
            if(this.props.mode=="week"){
                req= getViewBoosterDataWeek(this.props.video.channel_id);
            }else{
                req= getViewBoosterData24H(this.props.video.channel_id);
            }
        }
        req.then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            //console.log(responseJson);
                            var categories=[];
                            var series=[];
                            responseJson.map((item,index)=>{
                                //console.log(item);
                                categories.push(item.date);
                                series.push(item.view);
                            });
                            this.setState({
                                series: series,
                                categories:categories,
                                key:this.state.key+1
                            });
                            //.log(categories);
                            //console.log(series);
                        }
                    }).catch((error) => {
                    }).finally(() => {

                    });

    }
    componentDidMount() {
        this.refreshData();
    }
    render() {
        return (
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">{this.props.type==0?"Views chạy":"Subscribe chạy"} Chạy {this.props.mode=="week"?"7 Ngày qua":"24 giờ qua"}</h4>
                    <BarApexChart key={this.state.key} series={this.state.series} categories={this.state.categories}/>
                </CardBody>
            </Card>
        );
    }
}
