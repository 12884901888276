import React from "react"
import {Button} from 'reactstrap';
import {removeStream,updateStreamStatus} from '../../store/Playlist/ServiceApi'
import moment from 'moment'
class StreamingRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            stt: this.props.stt,
            id: this.props.id,
            usename: this.props.usename,
            phone: this.props.phone,
            email: this.props.email,
            status_number: this.props.status_number,
            status: this.props.status,
            money: this.props.money,

        }
        this.onClickStopStream=this.onClickStopStream.bind(this)
        this.onClickStopWaitStream=this.onClickStopWaitStream.bind(this)


    }

    componentDidMount() {

        //this.props.reloadViewVideo();
    }
    onClickStopStream(){
        if(window.confirm("Bạn có chắc ngừng stream luồng này?")){
            updateStreamStatus(2,"Chờ ngừng stream",this.props.stream_id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.props.loadStreams();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    onClickStopWaitStream(){
        if(window.confirm("Bạn có chắc ngừng chờ stream luồng này?")){
            updateStreamStatus(2,"Chờ ngừng stream",this.props.stream_id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.props.loadStreams();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    render() {
        if(this.state.status==-1){
            return null;
        }
        return <tr style={{height: 5}}>
            <td>{this.props.stt}</td>
            <td>{this.props.note}</td>
            <td>{this.props.computer_name}</td>
            <td>{this.state.status_number==0?"n/a":moment.unix(parseInt(this.props.start_stream_time)/1000).format("DD/MM HH:mm")  }</td>
            <td>{this.props.end_time==0?"n/a":(this.state.status_number==0?this.props.end_time+" phút sau khi start":moment.unix((parseInt(this.props.start_stream_time)+(this.props.end_time*60*1000) )/1000).format("DD/MM HH:mm"))}</td>
            <td>{this.props.current_value}</td>
            <td>{this.props.key_live}</td>
            <td>{this.state.status}</td>
            <td>
                {this.state.status_number==1?<Button size={'sm'} color={'danger'} onClick={this.onClickStopStream}>Ngừng Stream Ngay</Button>:null}
                {this.state.status_number==0?<Button size={'sm'} color={'warning'} >Đang chờ stream</Button>:null}
                {this.state.status_number==2?<Button size={'sm'} color={'warning'} >Đang chờ ngừng stream</Button>:null}
            </td>
        </tr>;
    }
}
export default StreamingRow;
