import React from "react"
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Button,
    Label
} from 'reactstrap';
import constant from '../../constant';
import moment from 'moment';

class ChannelUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            primaryThongke: false,
            removeMode: false,
            followMode: this.props.channel_status === 1 ? true : false,
            reload: this.props.reload,
            channel_id: this.props.channel_id,
            stt: this.props.stt,
            channel_title: this.props.channel_title,
            channel_action_status: this.props.channel_action_status,
            channel_upload_perday: this.props.channel_upload_perday,
            channel_computername: this.props.channel_computername,
            channel_upload_type: this.props.channel_upload_type,
            total_video_uploaded: this.props.total_video_uploaded,
            last_video_upload: this.props.last_video_upload,
            views:this.props.views,
            channel_upload_type_child: this.props.channel_upload_type_child,
            channel_delete: "",
            top:this.props.top,
            n_channel_action_status: this.props.channel_action_status,
            n_channel_upload_perday: this.props.channel_upload_perday,
            n_channel_upload_type: this.props.channel_upload_type,
            n_channel_upload_type_child: this.props.channel_upload_type_child,
            video_uploaded_today:this.props.video_uploaded_today
        }
    }
    removeChannel = () => {
        fetch("http://" + constant.pageUrl + "/backend/index.php/ChannelUploadController/deleteDataById", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "channel_id=" + this.state.channel_id + "&token=" + sessionStorage.getItem('token'), // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {

                } else {
                    this.setState({
                        primary: !this.state.primary,
                        removeMode: true,
                    });
                }
            }).catch((error) => {
            console.log(error);
        });
    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type === 'checkbox' ? (target.checked?0:1) : target.value;
        this.setState({
            [name]: value,
            insertMessage: ""
        });
    }


    updateChannel = (event) => {
        event.preventDefault();
        fetch("http://" + constant.pageUrl + "/backend/index.php/ChannelUploadController/updateData", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "channel_id=" + this.state.channel_id + "&token=" + sessionStorage.getItem('token')
                + "&channel_upload_perday=" +  this.state.n_channel_upload_perday+ "&channel_action_status="+this.state.n_channel_action_status
                + "&channel_upload_type=" +  this.state.n_channel_upload_type + "&channel_upload_type_child=" +  this.state.n_channel_upload_type_child
                + "&channel_computername="+this.state.channel_computername
            , // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {

                } else {
                    this.setState({
                        primary: !this.state.primary,
                        channel_upload_perday:this.state.n_channel_upload_perday,
                        channel_upload_type:this.state.n_channel_upload_type,
                        channel_action_status:this.state.n_channel_action_status,
                        channel_upload_type_child:this.state.n_channel_upload_type_child
                    });
                }
            }).catch((error) => {
            console.log(error);
        });
    }


    togglePrimary = (channel_id) => {
        this.setState({
            primary: !this.state.primary,
        });
    }
    togglePrimaryThongke = (channel_id) => {
        this.setState({
            primaryThongke: !this.state.primaryThongke,
        });
    }

    getChannelType=(type)=>{
      switch(type){
        case 0:
          return "Chờ";
        case 1:
          return "Báo";
        case 2:
          return "Nhạc";
        case 3:
          return "Reup";
        case 4:
          return "Tiktok";
        case 5:
          return "Từ Máy";
        default:
          return "Chờ";
      }
    }


    render() {

        if (!this.state.removeMode) {
            var CurrencyFormat = require('react-currency-format');
            return <tr>
                <td>{this.props.stt}</td>
                <td><a href={'https://www.youtube.com/channel/' + this.props.channel_id}>{this.props.channel_title}</a>
                </td>
                <td>{this.state.channel_action_status === 0 ? "Hoạt động" : "Tạm ngưng"}</td>
                <td>{this.state.top? <CurrencyFormat value={this.state.views} displayType={'text'}
                    thousandSeparator={true} prefix={''}
                    renderText={value => value}/>:this.state.video_uploaded_today.length +"/"+ this.state.channel_upload_perday}</td>
                <td>{this.getChannelType(this.state.channel_upload_type)}
                </td>
                <td>{this.state.channel_upload_type_child}</td>
                <td>{this.state.channel_computername}</td>
                <td>{this.props.total_video_uploaded}</td>
                <td>{this.props.views}</td>
                <td>{ moment().diff(moment(this.state.last_video_upload,"YYYY-MM-DD HH:mm:ss"),"days") } ngày trước</td>
                {this.state.top?
                    <td>
                        <Button size="sm" color="success" onClick={() => this.togglePrimaryThongke(this.props.channel_id)}><i
                            className="fa fa-info"></i>Chi tiết</Button>&nbsp;
                    </td>
                    :
                    <td>
                    <Button size="sm" color="success" onClick={() => this.togglePrimary(this.props.channel_id)}><i
                        className="fa fa-info"></i> Cài đặt</Button>&nbsp;
                </td>}
            </tr>;
        } else {
            return "";
        }
    }
}

export default ChannelUpload;
