import React, {Component} from "react";
import {Button, Card, CardBody, Col, Form, Label, Modal, PaginationItem, PaginationLink, Row} from 'reactstrap';
import {getPlaylists,removeError,refreshUpdating,scanAndCheck} from '../../store/Playlist/ServiceApi.js'
import PlayList from '../TableItem/PlayList'
import CardHeader from "reactstrap/es/CardHeader";
import PlaylistInfoModel from "../Action/PlaylistInfoModel";
class PlayListTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            date: "",
            hour: -1,
            togModel : false,
            playlistId: "",
            playlistTitle:"",
            videoOne:"",
            videoTwo:"",
            videoThree:"",
            page:0,
            filterMode:"last_view",
            limit:50,
            startPage:0,
            endPage :0,
            total:0,
            totalPage:0
        }
        this.showPlaylistInfo = this.showPlaylistInfo.bind(this);
        this.onHandleChange= this.onHandleChange.bind(this);
        this.removeError=this.removeError.bind(this);
        this.refreshUpdating=this.refreshUpdating.bind(this);
        this.scanPlaylistHaveError=this.scanPlaylistHaveError.bind(this);

    }
    componentDidMount() {
        this.getData(this.state.limit,this.state.page,this.state.filterMode);
    }
    onHandleChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        if(name=="filterMode"){
            this.getData(this.state.limit,this.state.page,value);
        }else if(name=="limit"){
            this.getData(value,this.state.page,this.state.filterMode);
        }
    }
    getData(limit,page,filterMode) {
        getPlaylists(limit,page,filterMode)
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.status=="success"){
                    this.setState({
                        data: responseJson.playlists,
                        filterMode:filterMode,
                        page:page,
                        limit:limit,
                        togModel : false,
                        startPage:responseJson.start,
                        endPage :responseJson.end,
                        total:responseJson.total,
                        totalPage:responseJson.totalPage
                    })
                }
            }).catch(error => {
            console.log(error);
        });
    }
    showPlaylistInfo(playlistId,playlistTitle,videoOne,videoTwo,videoThree){
       this.setState({
           togModel : true,
           playlistId: playlistId,
           playlistTitle:playlistTitle,
           videoOne:videoOne,
           videoTwo:videoTwo,
           videoThree:videoThree
       })
    }

    removeError(){
        if (window.confirm('Hành động : Xóa tất cả playlist chưa tạo được')) {
            removeError()
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson.status=="success"){
                       this.getData(this.state.limit,this.state.page,this.state.filterMode);
                    }
                }).catch(error => {
                console.log(error);
            });

        }
    }
    refreshUpdating(){
        if (window.confirm('Hành động : Refresh lại tất cả những playlist đang trong trạng thái update')) {
            refreshUpdating()
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson.status=="success"){
                        this.getData(this.state.limit,this.state.page,this.state.filterMode);
                    }
                }).catch(error => {
                console.log(error);
            });

        }
    }
    scanPlaylistHaveError(){
        if (window.confirm('Hành động : Scan và check video top của playlist (Nếu die cả 1 2 3 thì đưa playlist vào trạng thái xóa)')) {

            scanAndCheck()
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson.status=="success"){
                        this.getData(this.state.limit,this.state.page,this.state.filterMode);
                    }
                }).catch(error => {
                console.log(error);
            });

        }
    }
    render() {
        let mapReportPage = [];
        for (let i = this.state.startPage; i < this.state.endPage; i++) {
            mapReportPage[i] = i;
        }
        const pageView = mapReportPage.map((item, index) => {
            if (this.state.page === index) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => this.getData(this.state.limit,index,this.state.filterMode)  }>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => this.getData(this.state.limit,index,this.state.filterMode)  }>{index}</PaginationLink></PaginationItem>;
            }
        });
        let stt = this.state.page*this.state.limit;
        const dataTable = this.state.data.map((item, index) => {
            return (
                <PlayList
                    key={(""+Math.floor(Math.random() * 101))+item.id}
                    stt={++stt}
                    last_view={item.last_view}
                    playlist_title={item.playlist_title}
                    playlist_id={item.playlist_id}
                    channel_id={item.channel_id}
                    id={item.id}
                    updating={item.updating}
                    need_delete={item.need_delete}
                    video_one={item.video_one}
                    video_two={item.video_two}
                    video_three={item.video_three}
                    need_update={item.need_update}
                    config_id={item.config_id}
                    last_update_view_time={item.last_update_view_time}
                    list_video={item.list_video}
                    time={item.time}
                    showInfo={()=>this.showPlaylistInfo(item.playlist_id,item.playlist_title,item.video_one,item.video_two,item.video_three)}
                />
            );
        });
        return (
            <Card>
                <CardHeader>
                    <div>
                        <Row>
                            <Col sm={2}>
                                <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                >
                                   Sort By
                                </label>
                                    <select className="form-control"
                                            name={'filterMode'}
                                            onChange={this.onHandleChange} defaultValue={this.state.filterMode}>
                                        <option value={'last_view'}>Views</option>
                                        <option value={'date_make'}>Thời gian tạo</option>
                                    </select>
                            </Col>
                            <Col sm={2}>
                                    <label
                                        htmlFor="example-date-input"
                                        className="col-form-label"
                                    >
                                        Limits
                                    </label>
                                    <select className="form-control"
                                            name={'limit'}
                                            onChange={this.onHandleChange} defaultValue={this.state.limit}>
                                        <option value={10}>10</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={150}>150</option>
                                        <option value={200}>200</option>
                                    </select>

                            </Col>
                            <Col sm={2}>
                                <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                >
                                    Xóa Playlist Lỗi
                                </label>
                                <Button color={'danger'} onClick={this.removeError} className="form-control">Remove Error</Button>

                            </Col>
                            <Col sm={2}>
                                <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                >
                                    Refresh Playlist Đang Chạy
                                </label>
                                <Button color={'danger'} onClick={this.refreshUpdating} className="form-control">Refresh Updating</Button>

                            </Col>
                            <Col sm={2}>
                                <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                >
                                    Quét Playlist Có Video Die
                                </label>
                                <Button color={'danger'} disabled={true} onClick={this.scanPlaylistHaveError} className="form-control">Scan playlist (coming soon) </Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={12}>
                                <div className="float-right ">
                                    <ul data-test="pagination" className="pagination">
                                        {pageView}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="page-title-box">
                        <Row>
                            <Col sm={6}><h4 className="font-size-18">{this.state.total+" Playlists "+ this.state.totalPage+" Pages"}</h4></Col>
                        </Row>
                    </div>
                    <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Title</th>
                            <th scope="col">View (last check)</th>
                            <th scope="col">Update</th>
                            <th scope="col">Time</th>
                            <th scope="col">Channel Id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTable}
                        </tbody>
                    </table>
                    <div className="float-right ">
                        <ul data-test="pagination" className="pagination">

                        </ul>
                    </div>
                </CardBody>
                <PlaylistInfoModel key={Math.floor(Math.random() * 101)} togModel={this.state.togModel}
                                   playlistId={this.state.playlistId}
                                   playlistTitle={this.state.playlistTitle}
                                   videoOne={this.state.videoOne} videoTwo={this.state.videoTwo} videoThree={this.state.videoThree}

                />
            </Card>
        );
    }
}

export default PlayListTable;
