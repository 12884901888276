import {
    ADD_VIDEO_INTERACTIVE,
    REMOVE_VIDEO_INTERACTIVE,
    EDIT_VIDEO_INTERACTIVE,
    EDIT_VIDEO_SUCCESS_INTERACTIVE,
    FETCH_VIDEO_INTERACTIVE,
    FETCH_ERROR_INTERACTIVE,
    FETCH_SUCCESS_INTERACTIVE,
    FILTER_VIDEO_INTERACTIVE,
    ADD_VIDEO_SUCCESS_INTERACTIVE,
    REMOVE_VIDEO_SUCCESS_INTERACTIVE,
    FILTER_VIDEO_RUN_INTERACTIVE,
    FILTER_VIDEO_RUN_TYPE_INTERACTIVE,
    FILTER_VIDEO_STATUS_INTERACTIVE, FILTER_VIDEO_NOTE_INTERACTIVE
} from './actionTypes';


const initialState = {
    page: 0,
    videos: [],
    videosMAX: [],
    totalDay: 0,
    totalHour: 0,
    videoType: 0
}

function ViewInteractiveReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_VIDEO_SUCCESS_INTERACTIVE:
            return {
                ...state,
                videosMAX:[action.video].concat(state.videosMAX),
                videos: [action.video].concat(state.videosMAX)
        };
        case REMOVE_VIDEO_SUCCESS_INTERACTIVE:
            const data=state.videosMAX.filter((item, index) => {
                if (item.video_sub_id == action.video_sub_id) {
                    return false;
                }
                return true;
            });
            return {
                ...state,
                videos: data,
                videosMAX: data
            }
        case EDIT_VIDEO_SUCCESS_INTERACTIVE:
            const dataEdit=state.videosMAX.map((item, index) => {
                return item.video_sub_id == action.video.video_sub_id ?action.video:item;
            });
            return {
                ...state,
                videos: dataEdit,
                videosMAX: dataEdit
            }
        case FETCH_VIDEO_INTERACTIVE:
        /*
        return {
            videos:action.videos
        };
        */
        case FETCH_ERROR_INTERACTIVE:
            return {
                ...state,
                videos: [],
                videosMAX: [],
                totalDay: 0,
                totalHour: 0
            };
        case FETCH_SUCCESS_INTERACTIVE:
            return {
                ...state,
                videos: action.videos,
                videosMAX:action.videos,
                totalDay: action.totalDay,
                totalHour: action.totalHour
            };
        case FILTER_VIDEO_INTERACTIVE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    return item.video_sub_id.toLowerCase().includes(action.filter.toLowerCase());
                })
            };
        case FILTER_VIDEO_NOTE_INTERACTIVE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    //console.log(item.video_sub_id+" "+ item.video_note);
                    if(item.video_note.toLowerCase().includes(action.filter.toLowerCase())){
                        return true;
                    }else{
                        return false;
                    }

                })
            };
        case FILTER_VIDEO_RUN_TYPE_INTERACTIVE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }else{
                        return item.run_type==action.filter?true:false;
                    }
                })
            };
        case FILTER_VIDEO_STATUS_INTERACTIVE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }
                    return item.allow===action.filter?true:false;
                })
            }
        case FILTER_VIDEO_RUN_INTERACTIVE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }else if (action.filter==0){
                        return parseInt(item.video_sub_completed) >= parseInt(item.video_sub_max)?true:false;
                    }else{
                        return parseInt(item.video_sub_day_completed) >= parseInt(item.video_sub_per_day)?true:false;
                    }
                })
            };

        default:
            return state;
    }
}

export default ViewInteractiveReducer;
