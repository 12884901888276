import {ADD_VIDEO_INTERACTIVE,FETCH_VIDEO_INTERACTIVE,FILTER_VIDEO_INTERACTIVE,REMOVE_VIDEO_INTERACTIVE,EDIT_VIDEO_INTERACTIVE,FILTER_VIDEO_RUN_INTERACTIVE,FILTER_VIDEO_RUN_TYPE_INTERACTIVE,FILTER_VIDEO_STATUS_INTERACTIVE,FILTER_VIDEO_NOTE_INTERACTIVE} from "./actionTypes";


export const actionFetchData = (index, videoType) => {
    return {
        type:FETCH_VIDEO_INTERACTIVE,
        page:index,
        videoType:videoType
    }
}
export const actionFilterData = (filter) => {
    return {
        type:FILTER_VIDEO_INTERACTIVE,
        filter:filter
    }
}
export const actionFilterDataNote = (filter) => {
    return {
        type:FILTER_VIDEO_NOTE_INTERACTIVE,
        filter:filter
    }
}



export const actionFilterRunType = (filter) => {
    return {
        type:FILTER_VIDEO_RUN_TYPE_INTERACTIVE,
        filter:filter
    }
}
export const actionFilterRun = (filter) => {
    return {
        type:FILTER_VIDEO_RUN_INTERACTIVE,
        filter:filter
    }
}
export const actionFilterStatus = (filter) => {
    return {
        type:FILTER_VIDEO_STATUS_INTERACTIVE,
        filter:filter
    }
}


export const actionAddData = (video) => {
    return {
        type:ADD_VIDEO_INTERACTIVE,
        video:video
    }
}
export const actionRemoveData = (video_sub_id) => {
    return {
        type:REMOVE_VIDEO_INTERACTIVE,
        video_sub_id:video_sub_id
    }
}

export const actionEditData = (video) => {
    return {
        type:EDIT_VIDEO_INTERACTIVE,
        video:video
    }
}

