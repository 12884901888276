import React from "react";
import { Route, Redirect } from "react-router-dom";

//AUTH related methods
import { getFirebaseBackend } from "../helpers/authUtils";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      /*
      sử lý ở đây nếu như là dạng firebase
      const fireBaseBackend = getFirebaseBackend();
      if (isAuthProtected && !fireBaseBackend.getAuthenticatedUser()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      */
      if (isAuthProtected && (sessionStorage.getItem('token')==="" || sessionStorage.getItem('token')===null  ) ) {
            return (
                <Redirect
                    to={{ pathname: "/login", state: { from: props.location } }}
                />
            );
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
