import constant from "../../constant";


/**
 * Product
 * @param page
 * @returns {Promise<Response>}
 */
// AdminDataApi


export function showChannels(page) {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelController/getDatas/50/" + page, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}


export function showListChannelUpload(page,orderBy) {
  //console.log(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelUploadController/getDatas/50/" + page + "/"+orderBy);
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelUploadController/getDatas/50/" + page + "/"+orderBy, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}

export function showListChannelAction(page,search,server,rows) {
  //console.log(page+" "+search);

  let url= constant.protocal+"://" + constant.pageUrl + "/backend/index.php/AccountSubController/getDatas/"+rows+"/" + page + "/0";
  let body = "token=" + sessionStorage.getItem('token')+"&search="+search+"&server="+server;
  //console.log(url);
  //console.log(body);
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: body, // <-- Post parameters
    //<-- Post parameters
  });
}


export function getListComputerName() {
  //console.log(page+" "+search);
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/AccountSubController/getListComputerName/", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}




export function searchChannel(page,params) {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelController/getDatas/50/"+page, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: params,
    //<-- Post parameters
  });
}

export function searchChannelUpload(page,channelID,typeChild,type) {
   return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelUploadController/getChannelsSearch", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "likeFilter=" + channelID + "&typeChild="+typeChild+ "&type="+type+"&token=" + sessionStorage.getItem('token'),

    //<-- Post parameters
  });
}




export function getListChannelIn24h() {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getListChannelIn24h", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'),
    //<-- Post parameters
  });
}

export function getVideoViewsOfChannel(channel_id) {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getVideoViews24OfChannel/"+channel_id, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'),
    //<-- Post parameters
  });
}
export function getVideoUpload24H(page,maxrow,tag,from,to) {
  const url=constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getDatas24h/"+maxrow+"/" + page;
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token')+"&tag="+tag+"&from="+from+"&to="+to, // <-- Post parameters
    //<-- Post parameters
  });
}
export function getViewForVideo(id) {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getViewOfVideo/" + id, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}
export function getListChannelUpload() {
  return  fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelUploadController/getDatas/1000/0", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}

export function refreshViewsOfChannel(channel_id, url) {
  const query=constant.protocal+"://" + constant.pageUrl + "/backend/index.php/" + url + "/" + channel_id + "/0";
  return   fetch(query, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}

export function getTotalViewIncrease() {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewsController/getDataViewIncrease", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}

export function refreshData24h() {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoUploadController/refreshData24h", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}

export function getVideoGroupData() {
  return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoUploadController/getReportDatas/200/0", {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
    }),
    body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    //<-- Post parameters
  });
}






