
/////////////// video interactive ///////////////////
import constant from "../../constant";


export function GetListVideoInteractive(){
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoInteractiveController/getVideoInteractive/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
        //<-- Post parameters
    });
}

export function updateVideoInteractiveFromApplication(video) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoInteractiveController/updateVideoInteractiveFromApplication/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:
            "video_sub_id="+video.video_sub_id + "&video_sub_max="+video.video_sub_max
            +"&video_sub_per_day="+video.video_sub_per_day+"&channel_id="+video.channel_id
            +"&video_start_sub="+video.video_start_sub+"&video_sub_view_time="+video.video_sub_view_time+"&video_split_time="+video.video_split_time
            +"&allow="+video.allow+"&run_type="+video.run_type+"&run_type_child="+video.run_type_child+"&computer_name="+video.computer_name
            +"&video_note="+video.video_note+"&video_browser_type="+video.video_browser_type
            +"&token="+sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function insertVideoInteractiveFromApplication(video) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoInteractiveController/insertVideoInteractiveFromApplication/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:
            "video_sub_id="+video.video_sub_id + "&video_sub_max="+video.video_sub_max
            +"&video_sub_per_day="+video.video_sub_per_day+"&channel_id="+video.channel_id
            +"&video_start_sub="+video.video_start_sub+"&video_sub_view_time="+video.video_sub_view_time+"&video_split_time="+video.video_split_time
            +"&video_note="+video.video_note+"&video_browser_type="+video.video_browser_type
            +"&run_type="+video.run_type+"&run_type_child="+video.run_type_child+"&computer_name="+video.computer_name
            +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function deleteVideoInteractiveFromApplication(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoInteractiveController/deleteVideoInteractiveFromApplication/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"video_sub_id="+video_sub_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getListViewBoosterRunning() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/getListDataRunning/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getListViewBoosterSuccess(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/getListDataSuccess/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "video_sub_id=" + video_sub_id + "&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getViewBoosterDataWeek(video_sub_id) {
    console.log(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/getViewRunDataWeek/");
    console.log("video_sub_id "+video_sub_id);
    console.log( sessionStorage.getItem('token'));
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/getViewRunDataWeek/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"video_sub_id="+video_sub_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}

export function getViewBoosterData24H(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/getViewRunData24H/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"video_sub_id="+video_sub_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}

export function deleteViewBoosterFail() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/deleteViewBoosterFail/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function insertViewBoosterSuccess() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/insertViewBoosterSuccess/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function deleteViewLastDaily() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ViewBoosterController/deleteLastDaily/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}


/////////////////////////////////////////////////////////////////////


