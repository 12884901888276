import React from "react"
import {Badge, Progress} from 'reactstrap';
import {getViewForVideo} from '../../helpers/ServiceUtils/DataService';
import {Link} from "react-router-dom";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: false,
      removeMode: false,
      stt: this.props.stt,
      id: this.props.id,
      name: this.props.name,
      code: this.props.code,
      number_active: this.props.number_active,
      number_using: this.props.number_using,
      description: this.props.description,
      same_people: this.props.same_people,
    }
  }
  componentDidMount() {
    //this.props.reloadViewVideo();
    if(this.state.reload){
      this.reloadData();
    }

  }

  getName(){
    return this.state.name.length>50?this.state.name.substring(0, 50)+"...":this.state.name;
  }
  getDescription(){
    return this.state.description.length>100?this.state.description.substring(0, 100)+"...":this.state.description;
  }

  render() {
    return <tr style={{height:5}}>
              <td>{this.props.stt}</td>
              <td>{this.getName()}</td>
              <td>{this.state.code}</td>
              <td>{this.getDescription()}</td>
              <td>{this.state.number_active}</td>
              <td>{this.state.number_using}</td>
              <td><Badge color="primary" className="float-center">Hoàn thành</Badge></td>
              <td>{this.state.video_upload_ip}</td>
          </tr>;
  }
}

export default Product;
