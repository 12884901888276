import React from "react"
import {Badge, Button,Alert } from 'reactstrap';
import {removeAccount} from '../../store/Playlist/ServiceApi'
class AccountStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            reload: this.props.reload,
            account_id: this.props.account_id,
            username: this.props.username,
            channel_name: this.props.channel_name,
            channel_id: this.props.channel_id,
            stt: this.props.stt,
        }
        this.removeAccount = this.removeAccount.bind(this);
    }
    removeAccount(){
        if(window.confirm("Bạn có chắc chắn xóa tài khoản này?")){
            removeAccount(this.props.channel_id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.props.showData();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    render() {
        if (!this.state.removeMode) {
            return <tr>
                <td>{this.props.stt}</td>
                <td>{this.props.username}</td>
                <td><a href={'https://www.youtube.com/channel/' + this.props.channel_id}>{this.props.channel_id}</a></td>
                <td>
                    <Button size={'sm'} color={'danger'} onClick={()=>this.removeAccount(this.props.channel_id)}>Xóa</Button>&nbsp;
                    <Button size={'sm'} color={'primary'} onClick={()=>this.props.showStreamsOfAccount(this.props.channel_id)}>Chọn</Button>
                </td>
            </tr>;
        } else {
            return "";
        }
    }
}

export default AccountStream;
