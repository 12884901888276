import React from "react"
import {Button,Badge} from 'reactstrap';
import {actionRemoveData} from '../../store/viewinteractive/ViewInteractiveAction';
import {connect} from 'react-redux';
import {getViewOfVideo} from '../../helpers/ServiceUtils/ActionService';
class VideoInteractive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            reload: this.props.reload,
            video_sub_id: this.props.video_sub_id,
            video_sub_day_completed: this.props.video_sub_day_completed,
            video_sub_max: this.props.video_sub_max,
            video_sub_per_day: this.props.video_sub_per_day,
            video_sub_completed: this.props.video_sub_completed,
            video_sub_running: this.props.video_sub_running,
            video_sub_view_time: this.props.video_sub_view_time,
            video_start_sub: this.props.video_start_sub,
            computer_name: this.props.computer_name,
            channel_id: this.props.channel_id,
            video_current_sub: this.props.video_current_sub,
            video_sub_unique: this.props.video_sub_unique,
            run_type_child: this.props.run_type_child,
            run_type:this.props.run_type,
            loading: false,
        }
        this.removeVideo = this.removeVideo.bind(this);
        this.getRunType=this.getRunType.bind(this);
        this.getCurrentSub = this.getCurrentSub.bind(this);


    }

    componentDidMount() {
        this.getCurrentSub();
    }

    getCurrentSub = () => {
        this.setState({
            video_current_sub: 0
        })
        getViewOfVideo(this.props.video_sub_id)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson != false) {
                    this.setState({
                        video_current_sub: responseJson.view
                    })
                }
            }).catch((error) => {
            console.log(error);
        }).finally(() => {
        });
    }

    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
            insertMessage: ""
        });
    }


    removeVideo = () => {
        if (window.confirm('Bạn chắc chắn xóa video này ?')) {
            this.props.actionRemoveData(this.props.video_sub_id);
        }
    }
    refresh = () => {

    }
    getRunType(){
        switch (this.props.run_type) {
            case 0:
                return "Trang chủ";
            case 1:
                return "Đề xuất";
            case 2:
                return "Trực tiếp";
            case 3:
                return "Playlist";
            case 4:
                return "Tìm kiếm";
            default:
                return "Trang chủ a";
        }
    }
    render() {
        //console.log(this.state.video_current_sub);
        if (!this.state.removeMode) {
            return <tr onClick={() => this.props.handleChangeMode()}>
                <td>{this.props.stt}</td>
                <td><a href={'https://www.youtube.com/channel/' + this.state.channel_id}><i
                    className="ti-youtube"></i></a><a
                    href={'https://www.youtube.com/watch?v=' + this.state.video_sub_id}>{this.state.video_sub_id}</a>{" "}{this.props.allow==1?<i
                    className="ti-check"></i>:null}
                </td>
                <td>{this.state.video_start_sub + " + " + (this.state.video_current_sub - this.state.video_start_sub)}</td>
                <td>{(this.state.video_sub_completed == "undefined" ? 0 : this.state.video_sub_completed) + "/" + this.state.video_sub_max}</td>
                <td>{this.state.video_sub_day_completed+"/"+this.state.video_sub_per_day}</td>
                <td>{this.props.video_sub_hour_completed+"/"+(Math.ceil(this.state.video_sub_per_day/this.props.video_split_time))}</td>
                <td><Badge size={'sm'} color={'secondary'} style={{fontSize:16,color:'#7ed6df'}}>{this.props.video_running}</Badge></td>
                <td>{this.state.video_sub_unique}</td>
                <td>{this.getRunType()+" - "+(this.props.video_browser_type==0?"PC":"TV")}</td>
                <td>{this.state.computer_name==""?"Tất cả":this.state.computer_name}</td>
                <td>{this.state.video_sub_view_time}</td>
                <td><Button onClick={this.props.editHandle} size={'sm'} color={'success'}>Sửa</Button>{' '}<Button
                    onClick={this.removeVideo} size={'sm'} color={'danger'}>Xóa</Button>{' '}<Button
                    onClick={this.getCurrentSub} size={'sm'} color={'secondary'}>Refresh</Button></td>
            </tr>;
        } else {
            return null;
        }
    }
}

export default connect(null, {actionRemoveData})(VideoInteractive);
