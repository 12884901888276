import React, {Component} from 'react';
import {Card, CardBody,CardHeader, Form, Label, Button, Row, Col, Modal, PaginationItem, PaginationLink} from 'reactstrap';
import {
    GetListVideoAction,
    insertVideoSubFromApplication,
    updateVideoSubFromApplication,
    deleteVideoSubFromApplication
} from '../../helpers/ServiceUtils/ActionService';
import VideoInteractive from '../TableItem/VideoInteractive';
import {connect} from 'react-redux';
import {actionFetchData, actionFilterData,actionAddData,actionEditData,actionFilterRunType,actionFilterRun,actionFilterStatus,actionFilterDataNote} from '../../store/viewinteractive/ViewInteractiveAction'
import {FILTER_VIDEO_RUN_INTERACTIVE, FILTER_VIDEO_RUN_TYPE_INTERACTIVE, FILTER_VIDEO_STATUS_INTERACTIVE} from "../../store/viewinteractive/actionTypes";
import ViewInteractiveReducer from "../../store/viewinteractive/ViewInteractiveReducer";


class TableVideoInteractive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            modal_info: false,
            video_sub_id: "",
            video_sub_max: 500,
            video_sub_per_day: 40,
            channel_id: "",
            video_start_sub: 0,
            video_sub_view_time: "60-120",
            allow:0,
            run_type:0,
            video_split_time:24,
            run_type_child:"",
            insertMode: true,
            runTypeFilter:-1,
            statusFilter:-1,
            runFilter:-1,
            video_note:""
        }
        this.showDetail = this.showDetail.bind(this);
        this.showInsert = this.showInsert.bind(this);
        this.showEditDetail = this.showEditDetail.bind(this);
        this.insertOrUpdateData = this.insertOrUpdateData.bind(this);
        this.removeVideoSub = this.removeVideoSub.bind(this);
        this.onHandleChangeSearch = this.onHandleChangeSearch.bind(this);
    }
    showDetail() {
        this.setState({
            modal_info: !this.state.modal_info
        })
    }
    showEditDetail(video_sub_id, video_sub_max, video_sub_per_day, channel_id, video_start_sub, video_sub_view_time,allow,run_type,run_type_child,computer_name,video_split_time,video_note,video_browser_type){
        this.setState({
            modal_info: !this.state.modal_info,
            insertMode: false,
            video_sub_id: video_sub_id,
            video_sub_max: video_sub_max,
            video_sub_per_day: video_sub_per_day,
            channel_id: channel_id,
            video_start_sub: video_start_sub,
            video_sub_view_time: video_sub_view_time,
            run_type:run_type,
            computer_name:computer_name,
            run_type_child:run_type_child,
            allow:allow,
            video_split_time:video_split_time,
            video_note:video_note,
            video_browser_type:video_browser_type
        })
    }
    showInsert(mode) {
        this.setState({
            insertMode: true,
            modal_info: !this.state.modal_info,
        })
    }
    removeVideoSub(video_sub_id) {
        const newDAta = this.state.data.filter((item, index) => {
            return item.video_sub_id != video_sub_id;
        });
        this.setState({
            data: newDAta,
            dataMax: newDAta
        });
    }

    insertOrUpdateData() {
        if (this.state.insertMode) {
            this.props.actionAddData({
                video_sub_id: this.state.video_sub_id,
                video_sub_day_completed: 0,
                video_sub_max: this.state.video_sub_max,
                video_sub_per_day: this.state.video_sub_per_day,
                video_sub_completed: 0,
                video_sub_running: 0,
                video_browser_type:this.state.video_browser_type,
                video_sub_view_time: this.state.video_sub_view_time,
                video_start_sub: this.state.video_start_sub,
                video_current_sub: this.state.video_current_sub,
                video_sub_unique: 0,
                computer_name:this.state.computer_name,
                run_type:parseInt(this.state.run_type),
                run_type_child:this.state.run_type_child,
                channel_id: this.state.channel_id,
                video_split_time:this.state.video_split_time,
                video_note:this.state.video_note
            });
        } else {
            this.props.actionEditData({
                video_sub_id: this.state.video_sub_id,
                video_sub_day_completed: 0,
                video_sub_max: this.state.video_sub_max,
                video_sub_per_day: this.state.video_sub_per_day,
                video_sub_completed: 0,
                video_sub_running: 0,
                video_browser_type:this.state.video_browser_type,
                computer_name:this.state.computer_name,
                video_sub_view_time: this.state.video_sub_view_time,
                video_start_sub: this.state.video_start_sub,
                video_current_sub: this.state.video_current_sub,
                video_sub_unique: 0,
                run_type_child:this.state.run_type_child,
                run_type:parseInt(this.state.run_type),
                allow: this.state.allow,
                channel_id: this.state.channel_id,
                video_split_time:this.state.video_split_time,
                video_note:this.state.video_note
            });
        }
        this.setState({
            video_sub_id: "",
            video_sub_max: 500,
            video_sub_per_day: 40,
            channel_id: "",
            video_start_sub: 0,
            run_type:0,
            video_browser_type:0,
            run_type_child: "",
            computer_name: "",
            video_split_time:24,
            video_sub_view_time: "60-120",
            video_note:"",
            modal_info: !this.state.modal_info,
        })
    }
    onHandleChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }
    onHandleChangeSearch = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterData(value);
        this.setState({
            [name]: value,
            runTypeFilter:-1,
            statusFilter:-1,
            runFilter:-1,
            page:0,
        });
    }
    onHandleChangeSearchNote = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterDataNote(value);
        this.setState({
            [name]: value,
            runTypeFilter:-1,
            statusFilter:-1,
            runFilter:-1,
            page:0,
        });
    }
    onHandleChangeRunTypeFilter = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterRunType(value);
        this.setState({
            [name]: value,
            search:"",
            statusFilter:-1,
            runFilter:-1
        });
    }
    onHandleChangeRunFilter = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterRun(value);
        this.setState({
            [name]: value,
            search:"",
            runTypeFilter:-1,
            statusFilter:-1
        });
    }
    onHandleChangeStatusFilter = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterStatus(value);
        this.setState({
            [name]: value,
            search:"",
            runTypeFilter:-1,
            runFilter:-1
        });
    }


    loadData(page) {
        this.props.actionFetchData(page, this.props.type);

    }

    componentDidMount() {
        this.loadData(0);

    }

    render() {
        const numerReportPage = this.props.data.length / 10;
        let mapReportPage = [];
        for (let i = 0; i < numerReportPage; i++) {
            mapReportPage[i] = i;
        }
        const dataPageVideoAction = mapReportPage.map((item, index) => {
            if (this.state.page === index) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {
                        this.setState({page: index})
                    }}>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {
                        this.setState({page: index})
                    }}>{index}</PaginationLink></PaginationItem>;
            }
        });
        let stt = this.state.page * 10;
        const dataTable = this.props.data.slice(this.state.page * 10, (this.state.page + 1) * 10).map((item, index) => {
            return <VideoInteractive
                key={item.video_sub_id + "-" + item.video_sub_per_day + "-" + item.video_sub_max + "-" + item.video_start_sub + "-" + item.video_sub_view_time+"-"+item.computer_name+"-"+item.run_type+"-"+item.video_split_time+"-"+item.video_browser_type}
                video_sub_id={item.video_sub_id} stt={stt++}
                allow={item.allow}
                video_sub_day_completed={item.video_sub_day_completed} video_sub_max={item.video_sub_max}
                video_sub_per_day={item.video_sub_per_day} video_sub_completed={item.video_sub_completed}
                video_sub_running={item.video_sub_running} video_sub_view_time={item.video_sub_view_time}
                video_start_sub={item.video_start_sub} video_current_sub={item.video_current_sub}
                run_type={item.run_type} run_type_child={item.run_type_child}
                computer_name={item.computer_name} video_browser_type={item.video_browser_type}
                video_sub_hour_completed={item.video_sub_hour_completed}
                video_note={item.video_note}
                video_split_time={item.video_split_time}
                video_running={item.video_running}
                video_sub_unique={item.video_sub_unique} channel_id={item.channel_id}
                handleChangeMode={() => this.props.parent.chageModeView(item)}
                removeHanle={() => this.removeVideoSub(item.video_sub_id)}
                editHandle={() => this.showEditDetail(item.video_sub_id, item.video_sub_max, item.video_sub_per_day, item.channel_id, item.video_start_sub, item.video_sub_view_time,item.allow,item.run_type,item.run_type_child,item.computer_name,item.video_split_time,item.video_note,item.video_browser_type)}
            />;
        });
        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col sm={3}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Danh sách video hành động</h4>
                            </div>
                        </Col>
                        <label
                            htmlFor="example-text-input"
                            className="col-sm-1 col-form-label"
                        >
                            Kiểu chạy
                        </label>
                        <Col sm={1}>
                            <div className="float-right d-none d-md-block">
                                <select className="form-control"
                                        name={'runTypeFilter'}
                                        onChange={this.onHandleChangeRunTypeFilter} defaultValue={this.state.runTypeFilter}>
                                    <option value={-1}>Tất cả</option>
                                    <option value={0}>Trang chủ</option>
                                    <option value={1}>Đề xuất</option>
                                    <option value={2}>Trực tiếp</option>
                                    <option value={3}>Playlist</option>
                                    <option value={4}>Tìm kiếm</option>
                                </select>
                            </div>
                        </Col>
                        <label
                            htmlFor="example-text-input"
                            className="col-sm-1 col-form-label"
                        >
                            Trạng thái
                        </label>
                        <Col sm={1}>
                            <div className="float-right d-none d-md-block">
                                <select className="form-control"
                                        name={'statusFilter'}
                                        onChange={this.onHandleChangeStatusFilter} defaultValue={this.state.statusFilter}>
                                    <option value={-1}>Tất cả</option>
                                    <option value={0}>Đẵ ngừng</option>
                                    <option value={1}>Đang chạy</option>
                                </select>
                            </div>
                        </Col>
                        <label
                            htmlFor="example-text-input"
                            className="col-sm-1 col-form-label"
                        >
                            Lượng chạy
                        </label>
                        <Col sm={1}>
                            <div className="float-right d-none d-md-block">
                                <select className="form-control"
                                        name={'runFilter'}
                                        onChange={this.onHandleChangeRunFilter} defaultValue={this.state.runFilter}>
                                    <option value={-1}>Tất cả</option>
                                    <option value={0}>Đã chạy xong</option>
                                    <option value={1}>Đã chạy đủ hôm nay</option>
                                </select>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="float-right d-none d-md-block">
                                <Button onClick={() => this.props.parent.chageModeView("")} color={'primary'}>Tất
                                    cả</Button>{"   "}
                                <Button onClick={() => this.showInsert(this.props.type)} color={'primary'}>Thêm
                                    Video {this.props.type == 0 ? "View" : "Subscribe"}</Button>
                            </div>
                        </Col>
                    </Row>
                    <Form onSubmit={this.onsubmitSearch}>

                        <Row>
                            <Col sm={6}>
                                <input
                                    onChange={this.onHandleChangeSearch}
                                    className="form-control"
                                    type="text"
                                    name="search"
                                    placeholder='Tìm kiếm Video ...'
                                    id="example-text-input"
                                />
                            </Col>
                            <Col sm={6}>
                                <input
                                    onChange={this.onHandleChangeSearchNote}
                                    className="form-control"
                                    type="text"
                                    name="searchNote"
                                    placeholder='Tìm kiếm Theo Ghi Chú ...'
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>

                    </Form>
                </CardHeader>
                <CardBody>
                    <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">#</th>
                            <th scope="col">Bắt đầu</th>
                            <th scope="col">Đã chạy</th>
                            <th scope="col">Ngày</th>
                            <th scope="col">Giờ</th>
                            <th scope="col">Đang chạy</th>
                            <th scope="col">IP</th>
                            <th scope="col">Kiểu Chạy</th>
                            <th scope="col">Server</th>
                            <th scope="col" colSpan={2}>Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTable}
                        </tbody>
                    </table>
                    <div className="float-right ">
                        <ul data-test="pagination" className="pagination">
                            {dataPageVideoAction}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    toggle={this.showDetail}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{this.state.insertMode ? ("Thêm Video View ") : " Chỉnh Sửa Video View"}</h5>
                        <button
                            type="button"
                            onClick={this.showDetail}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Video ID
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="video_sub_id"
                                    readOnly={!this.state.insertMode}
                                    defaultValue={this.state.video_sub_id}
                                    onChange={this.onHandleChange}
                                    id="video_sub_id"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Ghi Chú
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="video_note"
                                    defaultValue={this.state.video_note}
                                    onChange={this.onHandleChange}
                                    id="video_note"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                ID Kênh
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="channel_id"
                                    readOnly={!this.state.insertMode}
                                    defaultValue={this.state.channel_id}
                                    onChange={this.onHandleChange}
                                    id="channel_id"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Số Lượng
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"video_sub_max"}
                                    defaultValue={this.state.video_sub_max}
                                    onChange={this.onHandleChange}
                                    id="video_sub_max"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Max / Ngày
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"video_sub_per_day"}
                                    defaultValue={this.state.video_sub_per_day}
                                    onChange={this.onHandleChange}
                                    id="video_sub_per_day"
                                />
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Bắt Đầu :
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'video_start_sub'}
                                    defaultValue={this.state.video_start_sub}
                                    onChange={this.onHandleChange}
                                    id="video_start_sub"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Thời gian giãn cách (giờ) :
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'video_split_time'}
                                    defaultValue={this.state.video_split_time}
                                    onChange={this.onHandleChange}
                                    id="video_split_time"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Thời Gian Xem
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'video_sub_view_time'}
                                    defaultValue={this.state.video_sub_view_time}
                                    onChange={this.onHandleChange}
                                    id="video_sub_view_time"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Actived
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'allow'}
                                        onChange={this.onHandleChange} defaultValue={this.state.allow}>
                                    <option value={0}>Ngưng</option>
                                    <option value={1}>Chạy</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Kiểu chạy
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'run_type'}
                                        onChange={this.onHandleChange} defaultValue={this.state.run_type}>
                                    <option value={0}>Trang Chủ</option>
                                    <option value={1}>Đề xuất</option>
                                    <option value={2}>Trực tiếp</option>
                                    <option value={3}>Playlist</option>
                                    <option value={4}>Tìm kiếm</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Loại trình duyệt
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'video_browser_type'}
                                        onChange={this.onHandleChange} defaultValue={this.state.video_browser_type}>
                                    <option value={0}>Browser PC</option>
                                    <option value={1}>Apple TV</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Nội dung kiểu xem | search , đề xuất
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'run_type_child'}
                                    defaultValue={this.state.run_type_child}
                                    onChange={this.onHandleChange}
                                    id="run_type_child"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Tên máy chủ :
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'computer_name'}
                                    defaultValue={this.state.computer_name}
                                    onChange={this.onHandleChange}
                                    id="computer_name"
                                />
                            </Col>
                        </Row>


                        <Row className="form-group">
                            <Col sm={2}>
                                <Button onClick={this.insertOrUpdateData} color={'primary'}>Thêm</Button>
                            </Col>
                            <Col sm={2}>
                                <Button onClick={this.showDetail} color={'danger'}>Thoát</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}

const mapStatetoProps = state => {
    return {
        data: state.ViewInteractiveReducer.videos,
        totalDay: state.ViewInteractiveReducer.totalDay,
        totalHour: state.ViewInteractiveReducer.totalHour
    }
}

export default connect(mapStatetoProps, {
    actionFetchData,
    actionFilterData,
    actionFilterDataNote,
    actionAddData,
    actionEditData,
    actionFilterRunType,
    actionFilterRun,
    actionFilterStatus
})(TableVideoInteractive);

