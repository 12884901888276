export const FETCH_CHANNELS="FETCH_CHANNELS";
export const FETCH_CHANNELS_SUCCESS="FETCH_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_ERROR="FETCH_CHANNELS_ERROR";
export const INSERT_CHANNELS="INSERT_CHANNELS";
export const INSERT_CHANNELS_SUCCESS="INSERT_CHANNELS_SUCCESS";
export const UPDATE_CHANNELS="UPDATE_CHANNELS";
export const UPDATE_CHANNELS_SUCCESS="UPDATE_CHANNELS_SUCCESS";
export const REMOVE_CHANNELS="REMOVE_CHANNELS";
export const REMOVE_CHANNELS_SUCCESS="REMOVE_CHANNELS_SUCCESS";
export const FILTER_CHANNELS="FILTER_CHANNELS";
