import React, { Component } from "react";
import { Row, Col, Button, Card,CardHeader, CardBody } from "reactstrap";
import "chartist/dist/scss/chartist.scss";
import ChannelStreamTable from "../../components/Table/ChannelStreamTable";
import ChannelStreamingTable from "../../components/Table/ChannelStreamingTable";
import AccountStreamTable from "../../components/Table/AccountStreamTable";
import ClientTable from "../../components/Table/ClientTable";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder_menu: false,
      tag_menu: false,
      more_menu: false,
      video: {
        video_sub_id: '',
        channel_id: ''
      },
      channel_id:''
    };
    this.toggleFolder = this.toggleFolder.bind(this);
    this.toggleTag = this.toggleTag.bind(this);
    this.toggleMore = this.toggleMore.bind(this);
    this.chageModeView = this.chageModeView.bind(this);
    this.showStreamsOfAccount = this.showStreamsOfAccount.bind(this);
  }

  toggleFolder() {
    this.setState(prevState => ({
      folder_menu: !prevState.folder_menu
    }));
  }

  toggleTag() {
    this.setState(prevState => ({
      tag_menu: !prevState.tag_menu
    }));
  }

  toggleMore() {
    this.setState(prevState => ({
      more_menu: !prevState.more_menu
    }));
  }

  chageModeView(video) {
    //console.log(video);
    this.setState({
      video: video
    });
  }

  componentDidMount() {
  }

  showStreamsOfAccount(channel_id){
    this.setState({
      channel_id:channel_id,
    })
  }

  render() {
    return (
        <React.Fragment>
          <br/>
          <div className="container-fluid">
            <Row className="">
              <Col sm={12}>
                <ClientTable  />
              </Col>
            </Row>
          </div>
          <br/>
          <div className="container-fluid">
            <Row className="">
              <Col sm={4}>
                <AccountStreamTable showStreamsOfAccount={this.showStreamsOfAccount}/>
              </Col>
              <Col sm={8}>
                <ChannelStreamTable key={this.state.channel_id+"-"+Math.random()} channel_id={this.state.channel_id} key_live={this.state.key_live} />
              </Col>
            </Row>
          </div>
          <br/>
          <div className="container-fluid">
            <Row className="">
              <Col sm={12}>
                <ChannelStreamingTable  />
              </Col>
            </Row>
          </div>
        </React.Fragment>
    );
  }
}


export default Dashboard;
