import constant from "../../constant";

export function  loginViaWebService(username,password) {
    console.log();
    return fetch(constant.protocal+"://" + constant.pageUrl +"/SSCOauth", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "username=" + username + "&passwd=" + password, // <-- Post parameters
    });
}
