import React, {Component} from "react";
import {Button, Card, CardBody, Col, Form, Label, Modal, Row} from 'reactstrap';
import {getListTimeBoosterRunning, getListTimeBoosterSuccess,deleteTimeBoosterFail,insertTimeBoosterSuccess,deleteTimeLastDaily} from '../../store/viewsub/ApiService'
import {getListViewBoosterRunning, getListViewBoosterSuccess,deleteViewBoosterFail,insertViewBoosterSuccess,deleteViewLastDaily} from '../../store/viewinteractive/ApiService'
import {getListSubBoosterRunning, getListSubBoosterSuccess,deleteSubBoosterFail} from '../../store/channelsub/ApiService'


import CTActionHistory from '../TableItem/CTActionHistory'
import ReloadActionHistory from '../Action/ReloadActionHistory'
import CardHeader from "reactstrap/es/CardHeader";

class CTActionVideoTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            date: "",
            hour: -1,
        }

    }

    componentDidMount() {
        this.getData(0);
    }

    getData(page) {
        if (this.props.type == 0) {
            if (this.props.video.video_sub_id == "") {
                getListTimeBoosterRunning()
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            this.setState({
                                data: responseJson
                            })
                        }
                    }).catch(error => {
                    console.log(error);
                });
            } else {
                getListTimeBoosterSuccess(this.props.video.video_sub_id)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            this.setState({
                                data: responseJson
                            })
                        }
                    }).catch(error => {
                    console.log(error);
                });
            }
        } else if (this.props.type == 1) {
            if (this.props.video.video_sub_id == "") {
                getListSubBoosterRunning()
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            this.setState({
                                data: responseJson
                            })
                        }
                    }).catch(error => {
                    console.log(error);
                });
            } else {
                getListSubBoosterSuccess(this.props.video.channel_id)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            this.setState({
                                data: responseJson
                            })
                        }
                    }).catch(error => {
                    console.log(error);
                });
            }
        } else {
            if (this.props.video.video_sub_id == "") {
                getListViewBoosterRunning()
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            this.setState({
                                data: responseJson
                            })
                        }
                    }).catch(error => {
                    console.log(error);
                });
            } else {
                getListViewBoosterSuccess(this.props.video.video_sub_id)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson != false) {
                            this.setState({
                                data: responseJson
                            })
                        }
                    }).catch(error => {
                    console.log(error);
                });
            }
        }
    }

    removeDaily(){
        if(this.props.type==0){
            deleteTimeLastDaily().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
        }else if (this.props.type==1){
            deleteSubBoosterFail().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
        }else{
            deleteViewLastDaily().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });

        }
    }


    removeError(){
        if(this.props.type==0){
            deleteTimeBoosterFail().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                       this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
        }else if (this.props.type==1){
            deleteSubBoosterFail().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
        }else{
            deleteViewBoosterFail().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });

        }
    }
    insertSuccess(){
        if(this.props.type==0){

            insertTimeBoosterSuccess().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
        }else if (this.props.type==1){

        }else{
            insertViewBoosterSuccess().then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });

        }
    }



    render() {
        let stt = 0;
        const dataTable = this.state.data.map((item, index) => {
            return (
                <CTActionHistory
                    key={this.props.type + "-" + item.id + "-" + item.current_time_load}
                    video_sub_id={item.video_id}
                    id={item.id}
                    action_channel_id={item.action_channel_id}
                    start_time={item.start_time}
                    status={item.status}
                    ip={item.ip}
                    computerName={item.computer_name}
                    realtime={this.props.realtime}
                    need_time={item.need_time}
                    in_time={item.in_time}
                    current_time_load={item.current_time_load}
                    current_quality={item.current_quality}
                    stt={stt++}
                />
            );
        });
        return (
            <Card>
                <CardHeader>
                    <div>
                        <Row>
                            <Col sm={8}>
                                <label
                                    htmlFor="example-date-input"
                                    className="col-form-label"
                                > Ngày
                                </label>
                                <Col sm={5}>
                                    <input
                                        onChange={this.onHandleChange}
                                        className="form-control"
                                        type="date"
                                        name={'fromDate'}
                                        defaultValue={this.state.date}
                                        id="example-date-input"
                                    />
                                </Col>
                            </Col>
                            <Col sm={4}>
                                <Row>

                                    <Col sm={4}>
                                        <Button onClick={()=>this.removeError()}>Xóa lỗi</Button>
                                    </Col>
                                    <Col sm={4}>
                                        <Button onClick={()=>this.insertSuccess()}>Xóa Thành Công</Button>
                                    </Col>
                                    <Col sm={4}>
                                        <Button onClick={()=>this.removeDaily()}>Xóa dữ liệu cũ</Button>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="page-title-box">
                        <Row>
                            <Col sm={9}>
                                <div className="float-right d-none d-md-block">
                                    <ReloadActionHistory reloadActionHistory={() => this.getData(0)}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">#</th>
                            <th scope="col">IP</th>
                            <th scope="col">Channel ID</th>
                            <th scope="col">Chuẩn</th>
                            <th scope="col">Bắt đầu</th>
                            <th scope="col">Computername</th>
                            <th scope="col">Time load</th>
                            {this.props.realtime ? <th scope="col">Đang Chạy</th> : null}
                            <th scope="col">Trạng thái</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTable}
                        </tbody>
                    </table>
                    <div className="float-right ">
                        <ul data-test="pagination" className="pagination">

                        </ul>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default CTActionVideoTable;
