import React, { Component } from "react";
import SettingMenu from "../../Shared/SettingMenu";
import { Row, Col, Card, CardBody } from "reactstrap";
import ChannelListTable from '../../../components/Table/ChannelListTable'
// Full Calendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { Link } from "react-router-dom";

const DefaultEvents = [
  {
    title: "All Day Event",
    start: new Date().setDate(new Date().getDate() + 1)
  },
  {
    id: 999,
    title: "Repeating Event",
    start: new Date().setDate(new Date().getDate() - 5),
    allDay: false,
    className: "bg-teal"
  },
  {
    id: 999,
    title: "Meeting",
    start: new Date().setDate(new Date().getDate() - 3),
    allDay: false,
    className: "bg-purple"
  },
  {
    id: 999,
    title: "Meeting",
    start: new Date().setDate(new Date().getDate() + 4),
    allDay: false,
    className: "bg-warning"
  },
  {
    title: "Meeting",
    start: new Date().setDate(new Date().getDate() + 4),
    allDay: false,
    className: "bg-danger"
  }
];

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarWeekends: true,
      calendarEvents: DefaultEvents
    };
    this.calendarComponentRef = React.createRef();
    this.handleDateClick = this.handleDateClick.bind(this);
  }
  componentDidMount() {}

  handleDateClick = arg => {
    var title = prompt("Event Title:");
    this.setState({ selectedDay: arg });
    if (title == null) {
    } else {
      var newEvent = {};
      newEvent = {
        id: this.state.calendarEvents.length + 1,
        title: title,
        start: this.state.selectedDay
          ? this.state.selectedDay.date
          : new Date(),
        className: "bg-primary"
      };
      this.setState({
        calendarEvents: this.state.calendarEvents.concat(newEvent),
        selectedDay: null
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Danh sách kênh</h4>
              </div>
            </Col>
            <Col sm={6}>
              <div className="float-right d-none d-md-block">
                <SettingMenu />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ChannelListTable />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Calendar;
