import React from "react"
import {Badge, Button} from 'reactstrap';
import moment from 'moment';
import constant from "../../constant";

class PlayList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account_username: this.props.account_username,
            sub_date: this.props.sub_date,
            action_channel_id: this.props.action_channel_id,
            sub_status: this.props.sub_status,
            account_computername: this.props.account_computername,
            timeLeftMinute: moment().diff(moment(this.props.time, "YYYY-MM-DD HH:mm:ss"), "days"),
            timeLeftSecond: moment().diff(moment(this.props.time, "YYYY-MM-DD HH:mm:ss"), "hour"),
            leftUpdate: moment().diff(moment(this.props.last_update_view_time, "x"), "hour")
        }
        //this.colUpdate=this.colUpdate.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
        const UpdateCol= ()=>{
            if (this.props.need_delete==1) {
                return (
                    <td>
                        <Badge color={"danger"}>Đang chờ xóa</Badge>
                    </td>
                );
            } else {
                return (
                    <td>
                        <Badge color={this.props.need_update == 1 ? "warning" : "primary"}>{this.props.need_update == 1 ? "Cần Update" : "Hoàn Thành"}</Badge>{" "}
                        {this.props.updating==1 ? <Badge color={"warning"}>Đang update</Badge> : null}
                    </td>
                );
            }
        }
        return <tr>
            <td>{this.props.stt}</td>
            <td><a
                href={'https://www.youtube.com/playlist?list=' + this.props.playlist_id}>{this.props.playlist_title.substring(0, 70)}</a>
            </td>
            <td>{this.props.last_view + " ( " + this.state.leftUpdate + " giờ )"}</td>
            {<UpdateCol />}
            <td>{this.state.timeLeftMinute + " ngày " + (this.state.timeLeftSecond % 24) + " giờ"}</td>
            <td><a href={'https://www.youtube.com/channel/' + this.props.channel_id}>{this.props.channel_id}</a></td>
            <td><Button onClick={() => this.props.showInfo()} color={'primary'} size={'sm'}><i
                className='ti-hand-point-up'></i></Button></td>
        </tr>;
    }
}

export default PlayList;
