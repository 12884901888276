import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';
import {watchFetchActionVideos,watchAddVideo,watchRemoveVideo,watchEditVideo} from './viewsub/ViewSubSagas';
import {watchFetchChannelSubscribes,watchAddChannelSubscribe,watchEditChannelSubscribe,watchDeleteChannelSubscribe} from './channelsub/ChannelSubSagas';
import {watchFetchInteractiveVideos,watchAddInteractiveVideo,watchEditInteractiveVideo,watchRemoveInteractiveVideo} from './viewinteractive/ViewInteractiveSagas';
import {watchFetchActionPLAYLISTs,watchAddPLAYLIST,watchEditPLAYLIST,watchRemovePLAYLIST} from './Playlist/PlaylistSagas';
export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        LayoutSaga(),
        watchFetchActionVideos(),
        watchAddVideo(),
        watchRemoveVideo(),
        watchEditVideo(),
        watchFetchChannelSubscribes(),
        watchAddChannelSubscribe(),
        watchEditChannelSubscribe(),
        watchDeleteChannelSubscribe(),
        watchFetchInteractiveVideos(),
        watchAddInteractiveVideo(),
        watchEditInteractiveVideo(),
        watchRemoveInteractiveVideo(),
        watchFetchActionPLAYLISTs(),
        watchAddPLAYLIST(),
        watchEditPLAYLIST(),
        watchRemovePLAYLIST()
    ])
}
