import React from "react"
import {Button} from 'reactstrap';
import moment from 'moment'
import {clientDelete} from '../../store/Playlist/ServiceApi'
class Client extends React.Component {
    constructor(props) {
        super(props);
        this.onClickRemoveAccount=this.onClickRemoveAccount.bind(this)
    }
    componentDidMount() {
        //this.props.reloadViewVideo();
    }
    onClickRemoveAccount(){
        if(window.confirm("Bạn có chắc chắn xóa máy này này?")){
            clientDelete(this.props.name)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.props.loadStreams();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    render() {
        return <tr style={{height: 5}}>
            <td>{this.props.stt}</td>
            <td>{this.props.name}</td>
            <td>{moment().diff(moment.unix(this.props.last_time/1000), "minutes")+" phút trước"}</td>
            <td>{this.props.count}</td>
            <td>
                <Button size={'sm'} color={'danger'} onClick={this.onClickRemoveAccount}>Xóa</Button>&nbsp;
            </td>
        </tr>;
    }
}
export default Client;
