import React,{Component} from "react";
import {Button,Input,Row,Col} from "reactstrap";
import {getListChannelIn24h, getVideoViewsOfChannel} from '../../helpers/ServiceUtils/DataService'
import moment from "moment";
class ReloadActionHistory extends Component{
    constructor(props) {
        super(props);
        this.state={
            timeCount:0,
            timeReload:120
        }
    }
    timer() {
        this.setState({
            timeCount:this.state.timeCount==this.state.timeReload?0:this.state.timeCount+1
        })
        if(this.state.timeCount==this.state.timeReload-1){
            this.props.reloadActionHistory();
        }
    }


    componentDidMount() {
        this.intervalId = setInterval(this.timer.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }


    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        if(isNaN(value)){
            this.setState({
                timeReload: 120,
            });
            return;
        }
        if(value<120){
            this.setState({
                timeReload: 120,
            });
            return;
        }
        this.setState({
            timeReload: value,
        });

    }

    render() {
        return (
            <div>
                <Row>
                    <Col sm={3}>
                        <Input
                            name={'timeReload'}
                            defaultValue={this.state.timeReload}
                            onChange={this.onHandleChange}
                        />
                    </Col>
                    <Col sm={9}>
                        <Button color={'primary'}
                                onClick={()=>{
                                    this.setState({
                                        timeCount:0
                                    });
                                    this.props.reloadActionHistory();
                                }}> {"Refresh sau "+this.state.timeCount+" / "+this.state.timeReload+"s | Click "}</Button>
                    </Col>
                </Row>

            </div>

        );
    }
}

export default ReloadActionHistory;
