import React,{Component} from "react";
import {Button, CardBody,Label, Col, Modal, Row } from "reactstrap";
import {Link } from "react-router-dom";
import {getPlaylistInfo,updatePlayList} from '../../store/Playlist/ServiceApi'
import {getPlaylists} from "../../store/Playlist/ServiceApi";
import PlayList from "../TableItem/PlayList";
export default class PlaylistInfoModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            togModel:this.props.togModel,
            videos:[],
            playlistTitle:this.props.playlistTitle,
            videoOne:this.props.videoOne,
            videoTwo:this.props.videoTwo,
            videoThree:this.props.videoThree,
            need_delete:this.props.need_delete,
            apiTitle:"",
            apiThumb:"",
            playlistId:this.props.playlistId
        }
        this.showDetail = this.showDetail.bind(this);
        this.updatePlaylist = this.updatePlaylist.bind(this);
    }
    showDetail() {
        this.setState({
            togModel: !this.state.togModel
        })
    }
    updatePlaylist(){
        let needUpdate=false;
        if(this.state.videoOne.length!=0 && this.state.videoOne!=this.state.videos[0].videoId){
            needUpdate=true;
        }
        if(this.state.videoTwo.length!=0 && this.state.videoTwo!=this.state.videos[1].videoId){
            needUpdate=true;
        }
        if(this.state.videoThree.length!=0 && this.state.videoThree!=this.state.videos[2].videoId){
            needUpdate=true;
        }
        if(this.state.playlistTitle!=this.state.apiTitle){
            needUpdate=true;
        }
        if(needUpdate){
            updatePlayList(this.state)
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson!=false){
                        console.log(responseJson);
                    }
                }).catch(error => {
                console.log(error);
            });
            console.log("need update");
        }else{
            console.log("0");
        }
        this.setState({
            togModel: !this.state.togModel
        })
    }
    componentDidMount() {
        if(this.props.playlistId!=""){
            getPlaylistInfo(this.props.playlistId)
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson!=false){
                        this.setState({
                            videos: responseJson.videos,
                            apiTitle:responseJson.apiTitle,
                            apiThumb:responseJson.apiThumb
                        })
                    }
                }).catch(error => {
                console.log(error);
            });
        }
    }
    onHandleChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }
    render() {
        const dataTable = this.state.videos.map((item, index) => {
            return (
               <tr>
                   <th>{index}</th>
                   <th><Label style={{textDecoration:"inherit"}}> <a href={"https://www.youtube.com/watch?v="+item.videoId}>{item.title.substring(0, 30)}</a> </Label></th>
               </tr>
            );
        });
        return (
            <Modal
                isOpen={this.state.togModel}
                className='modal-xl'
                toggle={this.showDetail}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">{this.props.playlistId}</h5>
                    <button
                        type="button"
                        onClick={this.showDetail}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="form-group">
                        <Col sm={6}>
                            <Row className="form-group">
                                <Col sm={12}>
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                    >
                                        Title
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="playlistTitle"
                                        defaultValue={this.state.playlistTitle}
                                        onChange={this.onHandleChange}
                                        id="note"
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col sm={12}>
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                    >
                                        Video 1
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="videoOne"
                                        defaultValue={this.state.videoOne}
                                        onChange={this.onHandleChange}
                                        id="note"
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col sm={12}>
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                    >
                                        Video 2
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="videoTwo"
                                        defaultValue={this.state.videoTwo}
                                        onChange={this.onHandleChange}
                                        id="note"
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col sm={12}>
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                    >
                                        Video 3
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="videoThree"
                                        defaultValue={this.state.videoThree}
                                        onChange={this.onHandleChange}
                                        id="note"
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col sm={12}>
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-form-label"
                                    >
                                        Video 3
                                    </label>
                                    <select className="form-control"
                                            name={'need_delete'}
                                            onChange={this.onHandleChange} defaultValue={this.state.need_delete}>
                                        <option value={0}>Duy Trì</option>
                                        <option value={1}>Xóa</option>
                                    </select>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6}>
                            <table className="table table-hover table-centered table-nowrap mb-0">
                                <thead>
                                <tr>
                                    <th scope="col">STT</th>
                                    <th scope="col">Title</th>
                                </tr>
                                </thead>
                                <tbody>
                                   {dataTable}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className="form-group">
                        <Col sm={2}>
                            <Button onClick={this.updatePlaylist} color={'primary'}>Lưu</Button>
                        </Col>
                        <Col sm={2}>
                            <Button onClick={this.showDetail} color={'danger'}>Thoát</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}
