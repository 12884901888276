 export const ADD_PLAYLIST="ADD_PLAYLIST";
 export const ADD_PLAYLIST_SUCCESS="ADD_PLAYLIST_SUCCESS";
 export const REMOVE_PLAYLIST="REMOVE_PLAYLIST";
 export const EDIT_PLAYLIST="EDIT_PLAYLIST";
 export const FETCH_PLAYLIST="FETCH_PLAYLIST";
 export const FETCH_SUCCESS="FETCH_SUCCESS";
 export const FETCH_ERROR="FETCH_ERROR";
 export const FILTER_PLAYLIST="FILTER_PLAYLIST";
 export const FILTER_PLAYLIST_NOTE="FILTER_PLAYLIST_NOTE";
 export const REMOVE_PLAYLIST_SUCCESS="REMOVE_PLAYLIST_SUCCESS";
 export const EDIT_PLAYLIST_SUCCESS="EDIT_PLAYLIST_SUCCESS";
 export const FILTER_PLAYLIST_RUN_TYPE="FILTER_PLAYLIST_RUN_TYPE";
 export const FILTER_PLAYLIST_RUN="FILTER_PLAYLIST_RUN";
 export const FILTER_PLAYLIST_STATUS="FILTER_PLAYLIST_STATUS";


