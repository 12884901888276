import React, {Component} from "react";
import {
    Card,
    CardBody,
    PaginationItem,
    PaginationLink,
    CardHeader,
    Form,
    Row,
    Col,
    Label,
    Input,
    Button, Modal
} from "reactstrap";
import {getAccounts,insertAccount} from '../../store/Playlist/ServiceApi'
import AccountStream from '../TableItem/AccountStream'
import Loading from "../Action/Loading";
class AccountStreamTable extends Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            pages:[],
            servers:[],
            rows:50,
            page:0,
            showModal: false,
            currentChannelId:'',
            currentKeyLive:'',
            username:'',
            loading:true,
            startPage:0,
            endPage:0,
            modal_username:'',
            modal_channelName:'',
            modal_channelId:'',
        }
        this.showDetail = this.showDetail.bind(this);
        this.togShowModal = this.togShowModal.bind(this);
    }

    togShowModal(){
            this.setState( {
                showModal: !this.state.showModal,
            });
    }


    showDetail(channel_id,username,passwd,recover,phone){
        this.setState( {
            currentChannelId:channel_id,
            username:username,
            passwd:passwd,
            recover:recover,
            phone:phone,
            showModal: !this.state.showModal,
            search:''
        });
    }
    componentDidMount() {
        this.showData("all",'all',0,this.state.rows);
    }
    insertAccountFunc = () => {
        var nameRegex = /^[a-zA-Z0-9]+$/;
        if(this.state.modal_channelId.length<24){
            alert("Channel id ít nhất 24 kí tự");
            return;
        }
        if(!this.state.modal_channelId.match(nameRegex)){
            alert("Channel id không hợp lệ");
            return;
        }
        this.setState({
            loading:true,
        })
        insertAccount(this.state)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.showData();
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }

    showData = () => {
        this.setState({
            loading:true,
            search:""
        })
        getAccounts()
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        data: responseJson.accounts,
                        startPage:responseJson.start,
                        endPage:responseJson.end,
                        page: 0,
                        showModal:false,
                        loading:false,
                        modal_username:'',
                        modal_channelName:'',
                        modal_channelId:'',
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }
    render() {
        const  tableItem = this.state.data.map((item, index) => {
            return <AccountStream key={item.account_id}
                                  account_id={item.account_id}
                                  stt={index+1}
                                  channel_id={item.channel_id}
                                  username={item.username}
                                  channel_name={item.channel_name}
                                  showData={this.showData}
                                  showStreamsOfAccount={this.props.showStreamsOfAccount}
            />;
        });
        const serverSelect  = this.state.servers.map((item,index)=>{
            return <option value={item}>{item}</option>;
        })

        let mapReportPage = [];
        for (let i = this.state.startPage; i < this.state.endPage; i++) {
            mapReportPage[i] = i;
        }
        const pageItem = mapReportPage.map((item, index) => {
            if (this.state.page === item) {
                return <PaginationItem key={index} active>
                    <PaginationLink tag="button" onClick={() => this.showData(this.state.server,this.state.search,item,this.state.rows)}>{item}</PaginationLink>
                </PaginationItem>;
            } else {
                return <PaginationItem key={index}>
                    <PaginationLink tag="button" onClick={() => this.showData(this.state.server,this.state.search,this.state.server,item,this.state.rows)}>{item}</PaginationLink>
                </PaginationItem>;
            }
        });
        return (
            <Card>
                <CardHeader>
                    <div>
                        <Button onClick={()=>this.togShowModal()} size={'sm'} color="primary">Thêm kênh</Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Kênh</th>
                                <th scope="col">ID kênh</th>
                                <th scope="col">Hành động</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.loading?<Loading />:tableItem}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">
                            {pageItem}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.togShowModal}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{"Kênh : "+this.state.currentChannelId}</h5>
                        <button
                            type="button"
                            onClick={() =>this.togShowModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Username
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.modal_username}
                                    id="modal_username"
                                    onChange={this.onHandleChange}
                                    name={"modal_username"}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                ID Kênh
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.modal_channelId}
                                    id="modal_channelId"
                                    onChange={this.onHandleChange}
                                    name={"modal_channelId"}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Tên Kênh
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.modal_channelName}
                                    id="modal_channelName"
                                    onChange={this.onHandleChange}
                                    name={"modal_channelName"}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Button onClick={()=>this.insertAccountFunc()} color="primary" className="mt-3" style={{ width: "100%" }}>
                                Lưu
                            </Button>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}
export default AccountStreamTable;
