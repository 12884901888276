import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import ViewSubReducer from "./viewsub/ViewSubReducer";
import ViewInteractiveReducer from "./viewinteractive/ViewInteractiveReducer";
import ChannelSubscribeReducer from "./channelsub/ChannelSubscribeReducer";
import PlayListReducer from "./Playlist/PlayListReducer";
const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ViewSubReducer,
  ChannelSubscribeReducer,
  ViewInteractiveReducer,
  PlayListReducer
});

export default rootReducer;
