import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    Label,
    Button,
    Row,
    Col,
    Modal,
    PaginationItem,
    PaginationLink,
    Input
} from 'reactstrap';
import PlayListConfig from '../TableItem/PlayListConfig';
import {
    getListPlaylistConfig,
    deletePlaylistConfig,
    updatePlaylistConfig,
    insertPlaylistConfig, getPlaylistConfigCategory
} from "../../store/Playlist/ServiceApi";
class TablePlaylistConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            modal_info: false,
            max_per_account :5,
            max_per_day_account: 20,
            max:100,
            keyword_list: '',
            id:'',
            title_list: '',
            max_video : 10,
            video_mix_type:0,
            title_mix_type:0,
            note :'',
            status :1,
            insertMode: true,
            runTypeFilter:-1,
            statusFilter:-1,
            runFilter:-1,
            data:[],
            dataMax:[],
            categories:[],
            category_id:"",
        }
        this.showDetail= this.showDetail.bind(this);
        this.showEditDetail = this.showEditDetail.bind(this);
        this.insertOrUpdateData = this.insertOrUpdateData.bind(this);
        this.removePlaylist = this.removePlaylist.bind(this);
        this.onHandleChangeSearch = this.onHandleChangeSearch.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataCategory = this.getDataCategory.bind(this);
    }
    showDetail() {
        this.setState({
            modal_info: !this.state.modal_info
        })
    }
    getData(){
        getListPlaylistConfig(this.props.category_id)
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.status="success"){
                    this.setState({
                        data: responseJson.configs,
                        dataMax: responseJson.configs,
                        max_per_account :5,
                        max_per_day_account: 20,
                        keyword_list: '',
                        title_list: '',
                        max_video : 10,
                        video_mix_type:0,
                        title_mix_type:0,
                        note :'',
                        status :1,
                        category_id:"",
                        modal_info: false,
                    })
                }
            }).catch(error => {
            console.log("getListPlaylistConfig "+error);
        });
    }
    insert(){
        insertPlaylistConfig(this.state)
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson!=false){
                    this.getData();
                }
            }).catch(error => {
            console.log(error);
        });
    }
    update(){
        updatePlaylistConfig(this.state)
            .then((response) => {
                return response.json()
            })
            .then((responseJson) => {
                if(responseJson!=false){
                    this.getData();
                }
            }).catch(error => {
            console.log(error);
        });
    }

    getDataCategory(){
        getPlaylistConfigCategory()
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.status="success"){
                    this.setState({
                        categories: responseJson.categories,
                    })
                }
            }).catch(error => {

            console.log(error);
        });
    }

    showEditDetail(playlist){
        this.getDataCategory();
        this.setState({
            modal_info: !this.state.modal_info,
            insertMode: false,
            id: playlist.id,
            max_per_account: playlist.max_per_account,
            max_per_day_account: playlist.max_per_day_account,
            keyword_list: playlist.keyword_list,
            title_list: playlist.title_list,
            max_video:playlist. max_video,
            video_mix_type: playlist.video_mix_type,
            title_mix_type:playlist.title_mix_type,
            category_id:playlist.category_id,
            note:playlist.note,
            status:playlist.status,
            max:playlist.max
        })
    }
    showInsert(mode) {
        this.getDataCategory();
        this.setState({
            insertMode: true,
            modal_info: !this.state.modal_info,
            max_per_account :5,
            max_per_day_account: 20,
            keyword_list: '',
            title_list: '',
            max_video : 10,
            video_mix_type:0,
            title_mix_type:0,
            category_id:"",
            note :'',
            status :1,
            max:100
        })
    }
    removePlaylist(id) {
        deletePlaylistConfig(id)
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson.status=="success"){
                    this.getData();
                }
            }).catch(error => {
            console.log(error);
        });
    }

    insertOrUpdateData() {
        if (this.state.insertMode) {
            this.insert(this.state);
        } else {
            this.update(this.state);
        }
    }
    onHandleChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }
    onHandleChangeSearch = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterData(value);
        this.setState({
            [name]: value,
            runTypeFilter:-1,
            statusFilter:-1,
            runFilter:-1,
            page:0,
        });
    }
    loadData() {
        this.getData();
    }
    componentDidMount() {
        this.loadData(0);
    }
    render() {
        const categories = this.state.categories.map((item, index) => {
            return (
                <option key={item.id} value={item.id}>{item.title}</option>
            );
        });
        const numerReportPage = this.state.data.length / 10;
        let mapReportPage = [];
        for (let i = 0; i < numerReportPage; i++) {
            mapReportPage[i] = i;
        }
        const dataPageVideoAction = mapReportPage.map((item, index) => {
            if (this.state.page === index) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {
                        this.setState({page: index})
                    }}>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {
                        this.setState({page: index})
                    }}>{index}</PaginationLink></PaginationItem>;
            }
        });
        let stt = this.state.page * 10;
        const dataTable = this.state.data.slice(this.state.page * 10, (this.state.page + 1) * 10).map((item, index) => {
            return <PlayListConfig
                key={item.id + "-" + item.max_per_account + "-" + item.max_per_day_account + "-" + item.keyword_list + "-" + item.title_list+"-"+item.max_video+"-"+item.video_mix_type+"-"+item.title_mix_type+"-"+item.note +"-"+item.status }
                id={item.id} stt={stt++}
                max_per_account={item.max_per_account}
                max_per_day_account={item.max_per_day_account}
                keyword_list={item.keyword_list}
                title_list={item.title_list}
                max_video={item.max_video}
                video_mix_type={item.video_mix_type}
                title_mix_type={item.title_mix_type}
                category_id={item.category_id}
                max={item.max}
                note={item.note}
                playlist_maked={item.playlist_maked}
                playlist_maked_today={item.playlist_maked_today}
                status={item.status}
                handleChangeMode={() => this.props.parent.chageModeView(item)}
                removeHanle={() => this.removePlaylist(item.id)}
                editHandle={() => this.showEditDetail(item)}
            />;
        });
        return (

            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col sm={12}>
                            <div className="float-right d-none d-md-block">
                                <Button onClick={() => this.showInsert(this.props.type)} color={'primary'}>Thêm Cấu Hình Playlist</Button>
                            </div>
                        </Col>
                    </Row>
                    <Form onSubmit={this.onsubmitSearch}>
                        <Row>
                            <Col sm={6}>
                                <input
                                    onChange={this.onHandleChangeSearchNote}
                                    className="form-control"
                                    type="text"
                                    name="searchNote"
                                    placeholder='Tìm kiếm Theo Ghi Chú ...'
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                    </Form>
                </CardHeader>
                <CardBody>
                    <table style={{'height': '380px', 'overflow': 'scroll', 'display': 'block'}} className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Ghi chú</th>
                            <th scope="col">Đã tạo</th>
                            <th scope="col">Đã tạo trong ngày</th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTable}
                        </tbody>
                    </table>
                    <div className="float-right ">
                        <ul data-test="pagination" className="pagination">
                            {dataPageVideoAction}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    className="modal-lg"
                    toggle={this.showDetail}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{this.state.insertMode ? ("Insert") : "Update"}</h5>
                        <button
                            type="button"
                            onClick={this.showDetail}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Ghi chú
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="note"
                                    defaultValue={this.state.note}
                                    onChange={this.onHandleChange}
                                    id="note"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Max / Account
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="max_per_account"
                                    defaultValue={this.state.max_per_account}
                                    onChange={this.onHandleChange}
                                    id="max_per_account"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Max
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="max"
                                    defaultValue={this.state.max}
                                    onChange={this.onHandleChange}
                                    id="max_per_account"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Max per day
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="max_per_day_account"
                                    defaultValue={this.state.max_per_day_account}
                                    onChange={this.onHandleChange}
                                    id="max_per_day_account"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Max Video / PLL
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"max_video"}
                                    defaultValue={this.state.max_video}
                                    onChange={this.onHandleChange}
                                    id="max_video"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Keyword List
                            </label>
                            <Col sm={9}>
                                <Input
                                    className="form-control"
                                    type="textarea"
                                    maxLength="2000"
                                    rows="3"
                                    name={"keyword_list"}
                                    defaultValue={this.state.keyword_list}
                                    onChange={this.onHandleChange}
                                    id="keyword_list"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Title List
                            </label>
                            <Col sm={9}>
                                <Input
                                    className="form-control"
                                    type="textarea"
                                    maxLength="2000"
                                    rows="3"
                                    name={"title_list"}
                                    defaultValue={this.state.title_list}
                                    onChange={this.onHandleChange}
                                    id="title_list"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Actived
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'status'}
                                        onChange={this.onHandleChange} defaultValue={this.state.status}>
                                    <option value={0}>Ngưng</option>
                                    <option value={1}>Chạy</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Tiêu Đề
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'title_mix_type'}
                                        onChange={this.onHandleChange} defaultValue={this.state.title_mix_type}>
                                    <option value={0}>Trộn keyword</option>
                                    <option value={1}>Trộn tiêu đề</option>
                                    <option value={2}>Lấy tiêu đề video</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Category
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'category_id'}
                                        onChange={this.onHandleChange} defaultValue={this.state.category_id}>
                                        <option value={''}>Chọn category</option>
                                        {categories}
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                              Kiểu tạo
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'video_mix_type'}
                                        onChange={this.onHandleChange} defaultValue={this.state.video_mix_type}>
                                    <option value={0}>Lấy view từ cao -> thấp time gần nhất</option>
                                    <option value={1}>Đề xuất</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col sm={2}>
                                <Button onClick={this.insertOrUpdateData} color={'primary'}>Thêm</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}
export default TablePlaylistConfig;
