import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import constant from "../../constant";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// actions
import { loginUser } from "../../store/actions";

// import images
import logoSm from "../../assets/images/logo-sm.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rcSuccess: false
    };
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    if (!this.state.rcSuccess && constant.RECAPTCHA ) {
      this.setState({
         messageError:"Nhập Captcha"
      })
      return;
    }
    this.props.loginUser(values, this.props.history);
  }
  onHandleChangeRC = (event) => {
    this.setState({
      rcSuccess: true
    });
  }
  recaptcha(){
    if(!constant.RECAPTCHA){
      return null;
    }else{
      return (
          <div className="mb-4">
            <ReCAPTCHA
                sitekey={constant.RECAPTCHAKEY}
                onChange={this.onHandleChangeRC}
            />
          </div>
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  {this.props.loading ? <Loader /> : null}
                  <Card className="overflow-hidden">
                    <div className="bg-primary">
                      <div className="text-primary text-center p-4">
                        <h5 className="text-white font-size-20">
                          35102 LIVE DASHBOARD
                        </h5>
                        <p className="text-white-50">
                          Sign in to continue manager
                        </p>
                        <Link to="/" className="logo logo-admin">
                          <img src={logoSm} height="24" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.props.error ? (
                            <Alert color="danger">{this.props.error}</Alert>
                          ) : null}
                          {this.state.messageError ? (
                              <Alert color="danger">{this.state.messageError}</Alert>
                          ) : null}
                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              value=""
                              placeholder="Enter email"
                              type="text"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Password"
                              type="password"
                              required
                              value=""
                              placeholder="Enter Password"
                            />
                          </div>
                          {this.recaptcha()}
                          <Row className="form-group">
                            <Col sm={6}>
                              &nbsp;
                              {/* <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                            <label className="custom-control-label" for="customControlInline">Remember me</label>
                                                        </div> */}
                            </Col>
                            <Col sm={6} className="text-right">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-5 text-center">
                  <p className="mb-0">
                    COPYRIGHT © {new Date().getFullYear()} 35102{" "}
                    <i className="mdi mdi-heart text-danger"></i> Make Great Together!
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(connect(mapStatetoProps, { loginUser })(Login));
