import { takeEvery,takeLatest, fork, put, all, call } from 'redux-saga/effects';
import {FETCH_CHANNELS,FETCH_CHANNELS_SUCCESS,FETCH_CHANNELS_ERROR, FILTER_CHANNELS, INSERT_CHANNELS, INSERT_CHANNELS_SUCCESS, REMOVE_CHANNELS, REMOVE_CHANNELS_SUCCESS, UPDATE_CHANNELS, UPDATE_CHANNELS_SUCCESS, } from "./actionTypes";
import {
    GetListChannelSubscribes,
    insertChannelSubscribe,
    updateChannelSubscribe,
    removeChannelSubscribe
} from "../../store/channelsub/ApiService";
function* fetchChannelSubscribes({page}) {
    try {
        const data=yield GetListChannelSubscribes();
        const responseJson=yield data.json();

        if(responseJson==false){
            yield put({
                type:FETCH_CHANNELS_ERROR
            });
        }else{
            yield put({
                type:FETCH_CHANNELS_SUCCESS,
                channelsubscribes: responseJson.channelsubscribes,
            });
        }
    }catch (e) {
        yield put({type:FETCH_CHANNELS_ERROR,error:e});
    }

}
export function* watchFetchChannelSubscribes () {
    yield takeEvery(FETCH_CHANNELS,fetchChannelSubscribes);
}

function* addChannelSubscribe({channelsubscribe}) {
    const data=yield insertChannelSubscribe(channelsubscribe);
    const responseJson=yield data.json();

    try {
        if(responseJson!=false){
            yield put({
                type:INSERT_CHANNELS_SUCCESS,
                channelsubscribe:channelsubscribe
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}
export  function* watchAddChannelSubscribe() {
    yield takeLatest(INSERT_CHANNELS,addChannelSubscribe);
}

function* editChannelSubscribe({channelsubscribe}) {
    const data=yield updateChannelSubscribe(channelsubscribe);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:UPDATE_CHANNELS_SUCCESS,
                channelsubscribe:channelsubscribe
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}
export  function* watchEditChannelSubscribe() {
    yield takeLatest(UPDATE_CHANNELS,editChannelSubscribe);
}

function* deleteChannelSubscribe({channel_subscribe_id}) {
    const data=yield removeChannelSubscribe(channel_subscribe_id);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:REMOVE_CHANNELS_SUCCESS,
                channel_subscribe_id:channel_subscribe_id
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}
export  function* watchDeleteChannelSubscribe() {
    yield takeLatest(REMOVE_CHANNELS,deleteChannelSubscribe);
}



/*
function* removeVideo({video_sub_id}) {
    const data=yield deleteVideoSubFromApplication(video_sub_id);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:REMOVE_VIDEO_SUCCESS,
                video_sub_id:video_sub_id
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}

export  function* watchRemoveVideo() {
    yield takeLatest(REMOVE_VIDEO,removeVideo);
}


function* editVideo({video}) {
    const data=yield updateVideoSubFromApplication(video);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:EDIT_VIDEO_SUCCESS,
                video:video
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}

export  function* watchEditVideo() {
    yield takeLatest(EDIT_VIDEO,editVideo);
}

 */
