import React, {Component} from "react";
import SettingMenu from "../../Shared/SettingMenu";
import {Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import ChannelUploadTable from '../../../components/Table/ChannelUploadTable'

class EmailTemplateBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <div className="page-title-box">
                                <h4 className="font-size-18">Billing Email</h4>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/#">Veltrix</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/#">Email Templates</Link>
                                    </li>
                                    <li className="breadcrumb-item active">Billing Email</li>
                                </ol>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="float-right d-none d-md-block">
                                <SettingMenu/>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col sm={12}>
                            <ChannelUploadTable/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailTemplateBilling;
