 export const ADD_VIDEO="ADD_VIDEO";
 export const ADD_VIDEO_SUCCESS="ADD_VIDEO_SUCCESS";
 export const REMOVE_VIDEO="REMOVE_VIDEO";
 export const EDIT_VIDEO="EDIT_VIDEO";
 export const FETCH_VIDEO="FETCH_VIDEO";
 export const FETCH_SUCCESS="FETCH_SUCCESS";
 export const FETCH_ERROR="FETCH_ERROR";
 export const FILTER_VIDEO="FILTER_VIDEO";
 export const FILTER_VIDEO_NOTE="FILTER_VIDEO_NOTE";
 export const REMOVE_VIDEO_SUCCESS="REMOVE_VIDEO_SUCCESS";
 export const EDIT_VIDEO_SUCCESS="EDIT_VIDEO_SUCCESS";
 export const FILTER_VIDEO_RUN_TYPE="FILTER_VIDEO_RUN_TYPE";
 export const FILTER_VIDEO_RUN="FILTER_VIDEO_RUN";
 export const FILTER_VIDEO_STATUS="FILTER_VIDEO_STATUS";


