import React, { Component } from "react";
import SettingMenu from "../../Shared/SettingMenu";
import { Row, Col, Card, CardBody } from "reactstrap";
// Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import images
import TableChannelSubscribes from "../../../components/Table/TableChannelSubscribes";
import ActionChart from "../../../components/Action/ActionChart";
import CTActionVideoTable from "../../../components/Table/CTActionVideoTable";
class EmailCompose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder_menu: false,
      tag_menu: false,
      more_menu: false,
      video:{
        video_sub_id:'',
        channel_id:''
      }
    };
    this.toggleFolder = this.toggleFolder.bind(this);
    this.toggleTag = this.toggleTag.bind(this);
    this.toggleMore = this.toggleMore.bind(this);
    this.chageModeView=this.chageModeView.bind(this);
  }

  toggleFolder() {
    this.setState(prevState => ({
      folder_menu: !prevState.folder_menu
    }));
  }

  toggleTag() {
    this.setState(prevState => ({
      tag_menu: !prevState.tag_menu
    }));
  }

  toggleMore() {
    this.setState(prevState => ({
      more_menu: !prevState.more_menu
    }));
  }

  chageModeView(video){
    this.setState({
      video:video
    });
  }

  componentDidMount() {}
  render() {
    return (
          <React.Fragment>
            <div className="container-fluid">
              <Row className="align-items-center">
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4 className="font-size-18">Tăng Theo dõi</h4>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="float-right d-none d-md-block">
                    <SettingMenu />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <TableChannelSubscribes parent={this} />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <ActionChart type={1} key={this.state.video_id+"-week"} mode={'week'} video={this.state.video}/>
                </Col>
                <Col sm={6}>
                  <ActionChart type={1} key={this.state.video_id+"-day"} mode={'day'} video={this.state.video}/>
                </Col>
              </Row>







              <Row>
                <Col sm={12}>
                  <CTActionVideoTable key={this.state.video.video_sub_id} video={this.state.video} realtime={true} type={1}/>
                </Col>
              </Row>
            </div>
          </React.Fragment>
    );
  }
}

export default EmailCompose;
