import React from "react"
import {Button,Badge} from 'reactstrap';
import moment from 'moment';
class Channel extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const timeDiff = moment(this.props.channel_lastupdate_time, "YYYY-MM-DD HH:mm:ss").diff(moment(this.props.channel_spin_time, "YYYY-MM-DD HH:mm:ss"), "hours") + " giờ " + moment(this.props.channel_lastupdate_time, "YYYY-MM-DD HH:mm:ss").diff(moment(this.props.channel_spin_time, "YYYY-MM-DD HH:mm:ss"), "minutes") % 60 + " phút ";
        const CurrencyFormat = require('react-currency-format');
        if (!this.props.removeMode) {
            return (
                <tr onClick={this.props.onShow}>
                    <td>{this.props.stt}</td>
                    <td><a href={'https://www.youtube.com/channel/'+this.props.channel_id}>{this.props.channel_title}</a></td>
                    <td>{this.props.channel_date}</td>
                    <td>{moment().diff(moment(this.props.channel_lastupdate_time, "YYYY-MM-DD HH:mm:ss"), "hours") + " giờ " + moment().diff(moment(this.props.channel_lastupdate_time, "YYYY-MM-DD HH:mm:ss"), "minutes") % 60 + " phút "}</td>
                    <td>{timeDiff} </td>
                    <td><CurrencyFormat value={this.props.channel_view} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value}/> / <CurrencyFormat value={this.props.channel_view_increase} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value}/></td>
                    <td>{this.props.channel_videos + " / " + this.props.channel_videos_life}</td>
                    <td><CurrencyFormat value={this.props.channel_subscriber} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value}/> / <CurrencyFormat value={(this.props.channel_subscriber_increase - this.props.channel_subscriber)} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value}/></td>
                    {this.props.realtime ? <td> {this.props.channel_last_video + " " + moment().diff(moment(this.props.channel_last_video, "YYYY-MM-DD HH:mm:ss"), "days") + " ngày trước"}</td> : ""}
                </tr>);
        } else {
            return "";
        }
    }
}

export default Channel;
