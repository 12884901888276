import React, {Component} from "react";
import SettingMenu from "../../Shared/SettingMenu";
import {Link} from "react-router-dom";
import {Card, CardBody, Form, Input} from 'reactstrap';
import {
    Row,
    Col,
    PaginationItem,
    PaginationLink,
    Button
} from "reactstrap";
// service
import {getVideoViewsOfChannel,getListChannelIn24h } from '../../../helpers/ServiceUtils/DataService'
import GroupVideoUploadTable from '../../../components/Table/GroupVideoUploadTable'
import TableProduct from '../../../components/Table/TableProduct'
import ReloadView24h from '../../../components/Action/ReloadView24h';


class EmailInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folder_menu: false,
            tag_menu: false,
            more_menu: false,
            reload: false,
            listVideoFilter:'top',
        };
        this.toggleFolder = this.toggleFolder.bind(this);
        this.toggleTag = this.toggleTag.bind(this);
        this.toggleMore = this.toggleMore.bind(this);
        this.onsubmitSearch = this.onsubmitSearch.bind(this);

    }




    componentDidMount() {

    }

    toggleFolder() {
        this.setState(prevState => ({
            folder_menu: !prevState.folder_menu
        }));
    }

    toggleTag() {
        this.setState(prevState => ({
            tag_menu: !prevState.tag_menu
        }));
    }
    toggleMore() {
        this.setState(prevState => ({
            more_menu: !prevState.more_menu
        }));
    }
    onsubmitSearch(e) {
        e.preventDefault();
    }
    changeVideoFilder(filter){
        this.setState({
            listVideoFilter:filter
        })
    }
    render() {
        return (
            <React.Fragment>
                <br/>
                <div className="container-fluid">
                    <Row>
                        <div>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <ReloadView24h />
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <TableProduct maxrow={50} tag={''}/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default EmailInbox;
