import { takeEvery,takeLatest, fork, put, all, call } from 'redux-saga/effects';
import {ADD_PLAYLIST,FETCH_SUCCESS,FETCH_ERROR,FETCH_PLAYLIST,EDIT_PLAYLIST,REMOVE_PLAYLIST,ADD_PLAYLIST_SUCCESS,REMOVE_PLAYLIST_SUCCESS,EDIT_PLAYLIST_SUCCESS} from "./actionTypes";
import {
    getListPlaylistConfig,
    updatePlaylistConfig,
    insertPlaylistConfig,
    deletePlaylistConfig
} from "../Playlist/ServiceApi";
function* fetchActionPLAYLISTs({page,category_id}) {
    try {
        const data=yield getListPlaylistConfig(category_id);
        const responseJson=yield data.json();
        if(responseJson==false){
            yield put({
                type:FETCH_ERROR
            });
        }else{
            yield put({
                type:FETCH_SUCCESS,
                page: page,
                category_id:category_id,
                playlists: responseJson.playlist_configs,
            });
        }
    }catch (e) {
        yield put({type:FETCH_ERROR,error:e});
    }

}
export function* watchFetchActionPLAYLISTs () {
    yield takeEvery(FETCH_PLAYLIST,fetchActionPLAYLISTs);
}

function* addPLAYLIST({playlist}) {

    const data=yield insertPlaylistConfig(playlist);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:ADD_PLAYLIST_SUCCESS,
                playlist:playlist
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}
export  function* watchAddPLAYLIST() {
    yield takeLatest(ADD_PLAYLIST,addPLAYLIST);
}

function* removePLAYLIST({id}) {
    const data=yield deletePlaylistConfig(id);
    const responseJson=yield data.json();
    try {
        if(responseJson.status=="success"){
            yield put({
                type:REMOVE_PLAYLIST_SUCCESS,
                id:id
            });
        }
    }catch(error){
        console.log("removePLAYLIST error "+error);
    }
}

export  function* watchRemovePLAYLIST() {
    yield takeLatest(REMOVE_PLAYLIST,removePLAYLIST);
}


function* editPLAYLIST({playlist}) {
    const data=yield updatePlaylistConfig(playlist);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:EDIT_PLAYLIST_SUCCESS,
                playlist:playlist
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}

export  function* watchEditPLAYLIST() {
    yield takeLatest(EDIT_PLAYLIST,editPLAYLIST);
}
