import {FILTER_CHANNELS,REMOVE_CHANNELS,UPDATE_CHANNELS,INSERT_CHANNELS,FETCH_CHANNELS} from './actionTypes'
export function actionFeactChannelSubscribes() {
    return {
        type : FETCH_CHANNELS
    }
}
export function actionInsertChannelSubscribe(channelsubscribe) {
    return {
        type : INSERT_CHANNELS,
        channelsubscribe :channelsubscribe
    }
}
export function actionUpdateChannelSubscribe(channelsubscribe) {
    return {
        type : UPDATE_CHANNELS,
        channelsubscribe :channelsubscribe
    }
}
export function actionRemoveChannelSubscribe(channel_subscribe_id) {
    return {
        type : REMOVE_CHANNELS,
        channel_subscribe_id :channel_subscribe_id
    }
}
