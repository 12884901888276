import React, {Component} from 'react';
import {Card, CardBody, Form, Label, Button, Row, Col, Modal, PaginationItem, PaginationLink} from 'reactstrap';
import ChannelSubscribe from '../TableItem/ChannelSubscribe';
import {connect} from 'react-redux';
import {actionFeactChannelSubscribes,actionInsertChannelSubscribe,actionUpdateChannelSubscribe} from '../../store/channelsub/ChannelSubscribeAction'

class TableChannelSubscribes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            modal_info: false,
            channel_subscribe_id: "",
            sub_per_day: 50,
            sub_max: 40,
            sub_begin: 0,
            video_view_time: "300-500",
            sub_completed:0,
            allow:1 ,
            listvideo:"",
            insertMode: true,
            sub_split:24,
            video_browser_type:0
        }
        this.showDetail = this.showDetail.bind(this);
        this.showInsert = this.showInsert.bind(this);
        this.showEditDetail = this.showEditDetail.bind(this);
        this.insertOrUpdateData = this.insertOrUpdateData.bind(this);
        this.removeVideoSub = this.removeVideoSub.bind(this);
        this.onHandleChangeSearch = this.onHandleChangeSearch.bind(this);
    }
    showDetail() {
        this.setState({
            modal_info: !this.state.modal_info
        })
    }
    showEditDetail(channel_subscribe_id, sub_max, sub_per_day, sub_begin, listvideo, video_view_time,sub_completed,sub_split,allow,video_browser_type){
        this.setState({
            modal_info: !this.state.modal_info,
            insertMode: false,
            channel_subscribe_id: channel_subscribe_id,
            sub_max: sub_max,
            sub_per_day: sub_per_day,
            sub_begin: sub_begin,
            listvideo: listvideo,
            video_view_time: video_view_time,
            sub_completed:sub_completed,
            sub_split:sub_split,
            allow:allow,
            video_browser_type:video_browser_type
        })
    }
    showInsert(mode) {
        this.setState({
            insertMode: true,
            modal_info: !this.state.modal_info,
            mode: mode
        })
    }
    removeVideoSub(video_sub_id) {
        const newDAta = this.state.data.filter((item, index) => {
            return item.video_sub_id != video_sub_id;
        });
        this.setState({
            data: newDAta,
            dataMax: newDAta
        });
    }

    insertOrUpdateData() {
        if (this.state.insertMode) {
            this.props.actionInsertChannelSubscribe(this.state);
        } else {

            this.props.actionUpdateChannelSubscribe(this.state);
        }
        this.setState({
            video_sub_id: "",
            video_sub_max: 500,
            video_sub_per_day: 40,
            channel_id: "",
            video_start_sub: 0,
            video_sub_view_time: "60-120",
            mode: 0,
            allow:1,
            video_browser_type:0,
            sub_split:24,
            modal_info: !this.state.modal_info,
        })
    }

    onHandleChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }
    onHandleChangeSearch = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.props.actionFilterData(value);
        this.setState({
            [name]: value,
        });
    }

    loadData(page) {
        this.props.actionFeactChannelSubscribes();
    }

    componentDidMount() {
        this.loadData(0);

    }

    render() {
        const numerReportPage = this.props.data.length / 10;
        let mapReportPage = [];
        for (let i = 0; i < numerReportPage; i++) {
            mapReportPage[i] = i;
        }
        const dataPageVideoAction = mapReportPage.map((item, index) => {
            if (this.state.page === index) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {
                        this.setState({page: index})
                    }}>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {
                        this.setState({page: index})
                    }}>{index}</PaginationLink></PaginationItem>;
            }
        });
        let stt = this.state.page * 10;
        const dataTable = this.props.data.slice(this.state.page * 10, (this.state.page + 1) * 10).map((item, index) => {
            return <ChannelSubscribe
                key={item.channel_subscribe_id + "-" + item.sub_per_day + "-" + item.sub_max + "-" + item.video_view_time + "-" + item.listvideo+"-"+item.sub_begin+"-"+item.sub_split+"-"+item.video_browser_type}
                channel_subscribe_id={item.channel_subscribe_id} stt={stt++}
                sub_per_day={item.sub_per_day} video_sub_max={item.sub_per_day}
                sub_max={item.sub_max} video_view_time={item.video_view_time}
                listvideo={item.listvideo} sub_begin={item.sub_begin}
                allow={item.allow}
                video_running={item.video_running} sub_hour_completed={item.sub_hour_completed}
                sub_completed={item.sub_completed} video_browser_type={item.video_browser_type}
                sub_day_completed={item.sub_day_completed}
                sub_split={item.sub_split}
                handleChangeMode={() => this.props.parent.chageModeView(item)}
                removeHanle={() => this.removeVideoSub(item.video_sub_id)}
                mode={this.props.type}
                editHandle={() => this.showEditDetail(item.channel_subscribe_id, item.sub_max, item.sub_per_day, item.sub_begin, item.listvideo,item.video_view_time,item.sub_completed,item.sub_split,item.allow,item.video_browser_type )}
            />;
        });
        return (
            <Card>
                <CardBody>
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Danh sách video hành động</h4>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="float-right d-none d-md-block">
                                <Button onClick={() => this.props.parent.chageModeView("")} color={'primary'}>Tất
                                    cả</Button>&nbsp;&nbsp;
                                <Button onClick={() => this.showInsert(this.props.type)} color={'primary'}>Thêm
                                    Video {this.props.type == 0 ? "View" : "Subscribe"}</Button>
                            </div>
                        </Col>
                    </Row>
                    <Form onSubmit={this.onsubmitSearch}>
                        <input
                            onChange={this.onHandleChangeSearch}
                            className="form-control"
                            type="text"
                            name="search"
                            placeholder='Tìm kiếm Video ...'
                            id="example-text-input"
                        />
                    </Form>
                    <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">#</th>
                            <th scope="col">Bắt đầu</th>
                            <th scope="col">Đã chạy</th>
                            <th scope="col">Ngày</th>
                            <th scope="col">Giờ</th>
                            <th scope="col">Đang chạy</th>
                            <th scope="col">Browser</th>
                            <th scope="col">IP</th>
                            <th scope="col" colSpan={2}>Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTable}
                        </tbody>
                    </table>
                    <div className="float-right ">
                        <ul data-test="pagination" className="pagination">
                            {dataPageVideoAction}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    toggle={this.showDetail}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{this.state.insertMode ? "Thêm Kênh Subscribe " : " Chỉnh Sửa Kênh Subscribe"}</h5>
                        <button
                            type="button"
                            onClick={this.showDetail}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               ID Kênh
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="channel_subscribe_id"
                                    readOnly={!this.state.insertMode}
                                    defaultValue={this.state.channel_subscribe_id}
                                    onChange={this.onHandleChange}
                                    id="channel_subscribe_id"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Số Lượng
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"sub_max"}
                                    defaultValue={this.state.sub_max}
                                    onChange={this.onHandleChange}
                                    id="sub_max"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Max / Ngày
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"sub_per_day"}
                                    defaultValue={this.state.sub_per_day}
                                    onChange={this.onHandleChange}
                                    id="sub_per_day"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Giãn cách (Giờ)
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={"sub_split"}
                                    defaultValue={this.state.sub_split}
                                    onChange={this.onHandleChange}
                                    id="sub_split"
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Bắt Đầu :
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'sub_begin'}
                                    defaultValue={this.state.sub_begin}
                                    onChange={this.onHandleChange}
                                    id="sub_begin"
                                />
                            </Col>
                        </Row>

                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Thời Gian Xem
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'video_view_time'}
                                    defaultValue={this.state.video_view_time}
                                    onChange={this.onHandleChange}
                                    id="video_view_time"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Danh sách video
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name={'listvideo'}
                                    defaultValue={this.state.listvideo}
                                    onChange={this.onHandleChange}
                                    id="listvideo"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Actived
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'allow'}
                                        onChange={this.onHandleChange} defaultValue={this.state.allow}>
                                    <option value={0}>Ngưng</option>
                                    <option value={1}>Chạy</option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Loại Browser
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'video_browser_type'}
                                        onChange={this.onHandleChange} defaultValue={this.state.video_browser_type}>
                                    <option value={0}>Browser PC</option>
                                    <option value={1}>Apple TV</option>
                                </select>
                            </Col>
                        </Row>



                        <Row className="form-group">
                            <Col sm={2}>
                                <Button onClick={this.insertOrUpdateData} color={'primary'}>Thêm</Button>
                            </Col>
                            <Col sm={2}>
                                <Button onClick={this.showDetail} color={'danger'}>Thoát</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}

const mapStatetoProps = state => {
    return {
        data: state.ChannelSubscribeReducer.channelsubscribes,
    }
}

export default connect(mapStatetoProps, {
    actionFeactChannelSubscribes,
    actionInsertChannelSubscribe,
    actionUpdateChannelSubscribe
})(TableChannelSubscribes);
