import constant from "../../constant";
export function GetListVideoAction(){

    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/getVideoSub/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
        //<-- Post parameters
    });
}
export function updateTimeBoosterFromApplication(video) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/updateVideoSubFromApplication/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:
            "video_sub_id="+video.video_sub_id + "&video_sub_max="+video.video_sub_max
            +"&video_sub_per_day="+video.video_sub_per_day+"&channel_id="+video.channel_id
            +"&video_start_sub="+video.video_start_sub+"&video_sub_view_time="+video.video_sub_view_time+"&video_split_time="+video.video_split_time
            +"&allow="+video.allow+"&run_type="+video.run_type+"&run_type_child="+video.run_type_child+"&computer_name="+video.computer_name
            +"&video_note="+video.video_note+"&video_browser_type="+video.video_browser_type
            +"&token="+sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function insertTimeBoosterFromApplication(video) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/insertVideoSubFromApplication/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:
            "video_sub_id="+video.video_sub_id + "&video_sub_max="+video.video_sub_max
            +"&video_sub_per_day="+video.video_sub_per_day+"&channel_id="+video.channel_id
            +"&video_start_sub="+video.video_start_sub+"&video_sub_view_time="+video.video_sub_view_time+"&video_split_time="+video.video_split_time
            +"&video_note="+video.video_note+"&video_browser_type="+video.video_browser_type
            +"&run_type="+video.run_type+"&run_type_child="+video.run_type_child+"&computer_name="+video.computer_name
            +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function deleteTimeBoosterFromApplication(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/deleteVideoSubFromApplication/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"video_sub_id="+video_sub_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}

export function getListTimeBoosterRunning() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/getListDataRunning/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getListTimeBoosterSuccess(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/getListDataSuccess/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "video_sub_id=" + video_sub_id + "&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getTimeBoosterDataWeek(video_sub_id) {
    console.log(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/getViewRunDataWeek/");
    console.log("video_sub_id "+video_sub_id);
    console.log( sessionStorage.getItem('token'));
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/getViewRunDataWeek/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"video_sub_id="+video_sub_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}

export function getTimeBoosterData24H(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/getViewRunData24H/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"video_sub_id="+video_sub_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function deleteTimeBoosterFail() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/deleteTimeBoosterFail/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function deleteTimeLastDaily() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/deleteLastDaily/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function insertTimeBoosterSuccess() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/TimeBoosterController/insertTimeBoosterSuccess/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}



