import React, {Component} from "react";
import {Card, CardBody, Col, Form, Input, Row, Button, Modal} from 'reactstrap';
import {
    PaginationItem,
    PaginationLink
} from "reactstrap";
// service
import {getProducts} from '../../helpers/ServiceUtils/AdminDataApi'
import Product from "../TableItem/Product";
import CardHeader from "reactstrap/es/CardHeader";
import moment from "moment";
class TableProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:0,
            data:[],
            startPage:0,
            endPage:0,
            total:0,
            modal_info:false,
            fromDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        }
        this.showInsert=this.showInsert.bind(this);
        this.showDetail=this.showDetail.bind(this);
    }
    componentDidMount() {
        this.showData(0,this.state.fromDate,this.state.toDate);
    }
    showDetail() {
        this.setState({
            modal_info: !this.state.modal_info
        })
    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
        if(name=='fromDate'){
            this.showData(0,value,this.state.toDate);
        }else{
            this.showData(0,this.state.fromDate,value);
        }
    }
    showData(page,from,to) {
        getProducts(page, this.props.maxrow, this.props.tag,from,to)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        page: page,
                        data: responseJson.products,
                        startPage:responseJson.startPage,
                        endPage:responseJson.endPage,
                        total:responseJson.total
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }
    showInsert() {
        this.setState({
            insertMode: true,
            modal_info: !this.state.modal_info,
            max_per_account :5,
            max_per_day_account: 20,
            description: '',
            title_list: '',
            max_video : 10,
            video_mix_type:0,
            title_mix_type:0,
            category_id:"",
            note :'',
            status :1,
            max:100
        })
    }
    render() {
        let stt = 1;
        const pages=[];
        const {startPage,endPage,page}=this.state;
        for (let i = startPage; i <endPage; i++){
            pages[i]=i;
        }
        const pageRow =pages.map(({item,index})=>{
            if (item==page) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => this.getData(this.state.limit,index,this.state.filterMode)  }>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => this.getData(this.state.limit,index,this.state.filterMode)  }>{index}</PaginationLink></PaginationItem>;
            }
        });
        const dataTable = this.state.data.map((item, index) => {
            return <Product key={this.props.tag + "-" + item.id}
                                stt={this.state.page * this.props.maxrow + stt++}
                                id={item.id}
                                name={item.name}
                                code={item.code}
                                number_active={item.number_active}
                                number_using={item.number_using}
                                same_people={item.same_people}
                                description={item.description}
            />;
        });
        return (
            <Card>
                {this.props.tag != '' ? null : <CardHeader>
                    <div>
                        <Row>
                            <Col sm={2}>
                                <Col sm={10}>
                                    <input
                                        onChange={this.onHandleChange}
                                        className="form-control"
                                        type="date"
                                        name={'fromDate'}
                                        defaultValue={this.state.fromDate}
                                        id="example-date-input"
                                    />
                                </Col>
                            </Col>
                            <Col sm={2}>
                                <Col sm={10}>
                                    <input
                                        onChange={this.onHandleChange}
                                        className="form-control"
                                        type="date"
                                        name={'toDate'}
                                        defaultValue={this.state.toDate}
                                        id="example-date-input"
                                    />
                                </Col>
                            </Col>
                            <Col sm={2}>
                                <Col sm={10}>
                                    <Button color={'primary'} onClick={this.showInsert} >ADD NEW PRODUCT</Button>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </CardHeader>}
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">NAME</th>
                                <th scope="col">CODE</th>
                                <th scope="col">DESCRIPTION</th>
                                <th scope="col">ACTIVED</th>
                                <th scope="col">USING</th>
                                <th scope="col">---</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataTable}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">
                            {pageRow}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    className="modal-lg"
                    toggle={this.showDetail}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{this.state.insertMode ? ("INSERT NEW PRODUCT") : "UPDATE"}</h5>
                        <button
                            type="button"
                            onClick={this.showDetail}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                NAME
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    defaultValue={this.state.name}
                                    onChange={this.onHandleChange}
                                    id="name"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                CODE
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    readOnly={true}
                                    name="code"
                                    defaultValue={this.state.code}
                                    onChange={this.onHandleChange}
                                    id="code"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                PEOPLES / KEY
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="same_people"
                                    defaultValue={this.state.same_people}
                                    onChange={this.onHandleChange}
                                    id="same_people"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                DESCRIPTION
                            </label>
                            <Col sm={9}>
                                <Input
                                    className="form-control"
                                    type="textarea"
                                    maxLength="3000"
                                    rows="10"
                                    name={"description"}
                                    defaultValue={this.state.description}
                                    onChange={this.onHandleChange}
                                    id="description"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col sm={2}>
                                <Button onClick={this.insertOrUpdateData} color={'primary'}>SUBMIT</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }

}

export default TableProduct;
