import React, {Component} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    Button,
    Row,
    Col,
    Modal,
    Input
} from 'reactstrap';
import {getPlaylistConfigCategory,deletePlaylistConfigCategory,insertPlaylistConfigCategory} from "../../store/Playlist/ServiceApi";
class TablePlaylistConfigCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title:"",
            id:"",
            playlist_config_categories:[]
        }
        this.deletePlaylist = this.deletePlaylist.bind(this);
        this.insertPlaylistCategory = this.insertPlaylistCategory.bind(this);
        this.onHandleChange = this.onHandleChange.bind(this);
        this.getData = this.getData.bind(this);

    }
    onHandleChange = (event) => {
        let target = event.target;
        let name = target.name;
        let value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }
    getData(){
        getPlaylistConfigCategory()
            .then((response) =>{
                return response.json();
            } )
            .then((responseJson) => {
                //console.log(responseJson);
                if(responseJson.status=="success"){
                    this.setState({
                        playlist_config_categories: responseJson.categories,
                        title:""
                    })
                }
            }).catch(error => {

            console.log(error);
        });
    }
    componentDidMount() {
        this.getData();
    }
    insertPlaylistCategory(){
        insertPlaylistConfigCategory(this.state)
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson!=false){
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
    }
    deletePlaylist(id){
        if (window.confirm('Bạn chắc chắn xóa category này')) {
            deletePlaylistConfigCategory(id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if(responseJson!=false){
                        this.getData();
                    }
                }).catch(error => {
                console.log(error);
            });
        }
    }
    render() {
        let stt=1;
        const dataTable = this.state.playlist_config_categories.map((item, index) => {
            return (
                <tr onClick={()=>this.props.parent.showPlaylistConfigViaCategory(item.id)} key={item.id}>
                    <th>{stt++}</th>
                    <th>{item.title}</th>
                    <th>
                        <Button onClick={()=>this.deletePlaylist(item.id)} size={'sm'} color={'danger'} >Xóa</Button>
                    </th>
                </tr>
            );
        });
        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col sm={12}>
                            <div className="float-right d-none d-md-block">
                                <Button onClick={this.insertPlaylistCategory} color={'primary'}>Thêm Category</Button>
                            </div>
                        </Col>
                    </Row>
                    <Form>
                        <Row>
                            <Col sm={6}>
                                <Input
                                    onChange={this.onHandleChange}
                                    className="form-control"
                                    type="text"
                                    name="title"
                                    defaultValue={this.state.title}
                                    placeholder='Nhập tên category'
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                    </Form>
                </CardHeader>
                <CardBody>
                    <table style={{'height': '380px', 'overflow': 'scroll', 'display': 'block'}} className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">Title</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataTable}
                        </tbody>
                    </table>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    className="modal-lg"
                    toggle={this.showDetail}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{this.state.insertMode ? ("Insert") : "Update"}</h5>
                        <button
                            type="button"
                            onClick={this.showDetail}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Name
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="note"
                                    defaultValue={this.state.note}
                                    onChange={this.onHandleChange}
                                    id="note"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col sm={2}>
                                <Button onClick={this.insertOrUpdateData} color={'primary'}>Thêm</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}
export default TablePlaylistConfigCategory;


