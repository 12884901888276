import React from "react"
import {Button,Badge} from 'reactstrap';
import {actionRemoveData} from '../../store/Playlist/PlaylistAction';
import {connect} from 'react-redux';
import {getViewOfVideo} from '../../helpers/ServiceUtils/ActionService';
class PlayListConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            reload: this.props.reload,
            id: this.props.id,
            max_per_account: this.props.max_per_account,
            max_per_day_account: this.props.max_per_day_account,
            keyword_list: this.props.keyword_list,
            title_list: this.props.title_list,
            max_video: this.props.max_video,
            video_mix_type: this.props.video_mix_type,
            title_mix_type: this.props.title_mix_type,
            note: this.props.note,
            status : this.props.status,
            playlist_maked: this.props.playlist_maked,
            playlist_maked_today: this.props.playlist_maked_today,
            loading: false,
        }
        this.remove = this.remove.bind(this);
        this.getRunType=this.getRunType.bind(this);
    }
    componentDidMount() {

    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
            insertMessage: ""
        });
    }
    remove = () => {
        if (window.confirm('Bạn chắc chắn xóa video này ?')) {
            this.props.removeHanle();
        }
    }
    refresh = () => {

    }
    getRunType(){
        switch (this.props.run_type) {
            case 0:
                return "Trang chủ";
            case 1:
                return "Đề xuất";
            case 2:
                return "Trực tiếp";
            case 3:
                return "Playlist";
            case 4:
                return "Tìm kiếm";
            default:
                return "Trang chủ a";
        }
    }
    render() {
        if (!this.state.removeMode) {
            return <tr onClick={() => this.props.handleChangeMode()}>
                <td>{this.props.stt}</td>
                <td>{this.props.note}</td>
                <td>{this.props.playlist_maked+"/"+this.props.max}</td>
                <td>{this.props.playlist_maked_today+"/"+this.props.max_per_day_account}</td>
                <td><Button onClick={this.props.editHandle} size={'sm'} color={'success'}>Sửa</Button>{' '}<Button
                    onClick={this.remove} size={'sm'} color={'danger'}>Xóa</Button>
                </td>
            </tr>;
        } else {
            return null;
        }
    }
}

export default PlayListConfig;
