import {
    ADD_VIDEO,
    REMOVE_VIDEO,
    EDIT_VIDEO,
    EDIT_VIDEO_SUCCESS,
    FETCH_VIDEO,
    FETCH_ERROR,
    FETCH_SUCCESS,
    FILTER_VIDEO,
    ADD_VIDEO_SUCCESS,
    REMOVE_VIDEO_SUCCESS,
    FILTER_VIDEO_RUN,
    FILTER_VIDEO_RUN_TYPE,
    FILTER_VIDEO_STATUS, FILTER_VIDEO_NOTE
} from './actionTypes';


const initialState = {
    page: 0,
    videos: [],
    videosMAX: [],
    totalDay: 0,
    totalHour: 0,
    videoType: 0
}

function ViewSubReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_VIDEO_SUCCESS:
            return {
                ...state,
                videosMAX:[action.video].concat(state.videosMAX),
                videos: [action.video].concat(state.videosMAX)
        };
        case REMOVE_VIDEO_SUCCESS:
            const data=state.videosMAX.filter((item, index) => {
                if (item.video_sub_id == action.video_sub_id) {
                    return false;
                }
                return true;
            });
            return {
                ...state,
                videos: data,
                videosMAX: data
            }
        case EDIT_VIDEO_SUCCESS:
            const dataEdit=state.videosMAX.map((item, index) => {
                return item.video_sub_id == action.video.video_sub_id ?action.video:item;
            });
            return {
                ...state,
                videos: dataEdit,
                videosMAX: dataEdit
            }
        case FETCH_VIDEO:
        /*
        return {
            videos:action.videos
        };
        */
        case FETCH_ERROR:
            return {
                ...state,
                videos: [],
                videosMAX: [],
                totalDay: 0,
                totalHour: 0
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                videos: action.videos,
                videosMAX:action.videos,
                totalDay: action.totalDay,
                totalHour: action.totalHour
            };
        case FILTER_VIDEO:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    return item.video_sub_id.toLowerCase().includes(action.filter.toLowerCase());
                })
            };
        case FILTER_VIDEO_NOTE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    //console.log(item.video_sub_id+" "+ item.video_note);
                    if(item.video_note.toLowerCase().includes(action.filter.toLowerCase())){
                        return true;
                    }else{
                        return false;
                    }

                })
            };
        case FILTER_VIDEO_RUN_TYPE:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }else{
                        return item.run_type==action.filter?true:false;
                    }
                })
            };
        case FILTER_VIDEO_STATUS:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }
                    return item.allow===action.filter?true:false;
                })
            }
        case FILTER_VIDEO_RUN:
            return {
                ...state,
                videos: state.videosMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }else if (action.filter==0){
                        return parseInt(item.video_sub_completed) >= parseInt(item.video_sub_max)?true:false;
                    }else{
                        return parseInt(item.video_sub_day_completed) >= parseInt(item.video_sub_per_day)?true:false;
                    }
                })
            };

        default:
            return state;
    }
}

export default ViewSubReducer;
