import React from "react"
import {Badge} from 'reactstrap';
import moment from 'moment';
import constant from "../../constant";

class CTActionHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account_username: this.props.account_username,
            sub_date: this.props.sub_date,
            action_channel_id: this.props.action_channel_id,
            sub_status: this.props.sub_status,
            account_computername: this.props.account_computername,
            timeLeftMinute: 0,
            timeLeftSecond: 0,
        }
    }

    componentDidMount() {
        //if (this.props.realtime) {
        //    this.intervalId = setInterval(this.timer.bind(this), 1000);
        //}
    }

    componentWillUnmount() {
       //clearInterval(this.intervalId);
    }

    refreshVideo = () => {
        fetch("http://" + constant.pageUrl + "/backend/index.php/VideoSubController/refreshCTVideoSub", {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
            }),
            body: "video_sub_id=" + this.state.video_sub_id + "&account_channel_id=" + this.state.action_channel_id + "&token=" + sessionStorage.getItem('token')
            , // <-- Post parameters
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson === false) {

                } else {
                    this.setState({
                        sub_status: responseJson.sub_status
                    });
                    console.log("responseJson.sub_status " + responseJson.sub_status);
                    if (responseJson.sub_status !== 0) {
                        clearInterval(this.intervalId);
                    }
                }


            }).catch((error) => {
            console.log(error);
        });
    }
    getStatus(status){
        if(status==0){
            return  <Badge color="warning" className="float-center">Đang chạy</Badge>;
        }else if (status==1){
            return  <Badge color="success" className="float-center">Hoàn thành</Badge>;
        }else{
            return  <Badge color="danger" className="float-center">Lỗi</Badge>;
        }
    }
    render() {
        return <tr>
            <td>{this.props.stt +" "+this.props.id}</td>
            <td><a href={'https://www.youtube.com/watch?v=' + this.props.video_sub_id}>{this.props.video_sub_id}</a></td>
            <td>{this.props.ip}</td>
            <td>{this.props.action_channel_id}</td>
            <td>{this.props.current_quality=="large"?"480P":"360P"}</td>
            <td>{this.props.start_time.split(" ")[1]+" ["+moment().diff(moment(this.props.start_time, "YYYY-MM-DD HH:mm:ss"), "minutes")+"]"}</td>
            <td>{this.props.computerName}</td>
            <td>{Math.round(this.props.current_time_load/60)+" phút"}</td>
            {this.props.realtime?<td>{Math.round(this.props.in_time/60) + "/" + Math.round(this.props.need_time / 60) + " phút"}</td>:null}
            <td>{this.getStatus(this.props.status)}</td>
        </tr>;
    }
}

export default CTActionHistory;
