import {
    EDIT_PLAYLIST_SUCCESS,
    FETCH_PLAYLIST,
    FETCH_ERROR,
    FETCH_SUCCESS,
    FILTER_PLAYLIST,
    ADD_PLAYLIST_SUCCESS,
    REMOVE_PLAYLIST_SUCCESS,
    FILTER_PLAYLIST_STATUS,
    FILTER_PLAYLIST_NOTE
} from './actionTypes';


const initialState = {
    page: 0,
    playlists: [],
    playlistsMAX: []
}

function PlayListReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_PLAYLIST_SUCCESS:
            return {
                ...state,
                playlistsMAX:[action.playlist].concat(state.playlistsMAX),
                playlists: [action.playlist].concat(state.playlistsMAX)
        };
        case REMOVE_PLAYLIST_SUCCESS:
            const data=state.playlistsMAX.filter((item, index) => {
                if (item.id == action.id) {
                    return false;
                }
                return true;
            });
            return {
                ...state,
                playlists: data,
                playlistsMAX: data
            }
        case EDIT_PLAYLIST_SUCCESS:
            const dataEdit=state.playlistsMAX.map((item, index) => {
                return item.id == action.playlist.id ?action.playlist:item;
            });
            return {
                ...state,
                playlists: dataEdit,
                playlistsMAX: dataEdit
            }
        case FETCH_ERROR:
            return {
                ...state,
                playlists: [],
                playlistsMAX: [],
                totalDay: 0,
                totalHour: 0
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                playlists: action.playlists,
                playlistsMAX:action.playlists,
            };
        case FILTER_PLAYLIST:
            return {
                ...state,
                playlists: state.playlistsMAX.filter((item, index) => {
                    return item.note.toLowerCase().includes(action.filter.toLowerCase());
                })
            };
        case FILTER_PLAYLIST_NOTE:
            return {
                ...state,
                playlists: state.playlistsMAX.filter((item, index) => {
                    //console.log(item.PLAYLIST_sub_id+" "+ item.PLAYLIST_note);
                    if(item.note.toLowerCase().includes(action.filter.toLowerCase())){
                        return true;
                    }else{
                        return false;
                    }

                })
            };
        case FILTER_PLAYLIST_STATUS:
            return {
                ...state,
                playlists: state.playlistsMAX.filter((item, index) => {
                    if(action.filter==-1){
                        return true;
                    }
                    return item.status===action.filter?true:false;
                })
            }
        default:
            return state;
    }
}

export default PlayListReducer;
