import { takeEvery,takeLatest, fork, put, all, call } from 'redux-saga/effects';
import {ADD_VIDEO_INTERACTIVE,FETCH_SUCCESS_INTERACTIVE,FETCH_ERROR_INTERACTIVE,FETCH_VIDEO_INTERACTIVE,
    EDIT_VIDEO_INTERACTIVE,REMOVE_VIDEO_INTERACTIVE,ADD_VIDEO_SUCCESS_INTERACTIVE,REMOVE_VIDEO_SUCCESS_INTERACTIVE,EDIT_VIDEO_SUCCESS_INTERACTIVE} from "./actionTypes";
import {
    deleteVideoInteractiveFromApplication,
    GetListVideoInteractive,
    insertVideoInteractiveFromApplication,
    updateVideoInteractiveFromApplication
} from "../../store/viewinteractive/ApiService";
function* fetchActionVideos({page}) {
    try {
        const data=yield GetListVideoInteractive();
        const responseJson=yield data.json();
        if(responseJson==false){
            yield put({
                type:FETCH_ERROR_INTERACTIVE
            });
        }else{
            yield put({
                type:FETCH_SUCCESS_INTERACTIVE,
                page: page,
                videos: responseJson.videosubs,
                totalDay: responseJson.totalDay,
                totalHour: responseJson.totalHour,
            });
        }
    }catch (e) {
        yield put({type:FETCH_ERROR_INTERACTIVE,error:e});
    }

}
export function* watchFetchInteractiveVideos () {
    yield takeEvery(FETCH_VIDEO_INTERACTIVE,fetchActionVideos);
}

function* addVideo({video}) {
    const data=yield insertVideoInteractiveFromApplication(video);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:ADD_VIDEO_SUCCESS_INTERACTIVE,
                video:video
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}
export  function* watchAddInteractiveVideo() {
    yield takeLatest(ADD_VIDEO_INTERACTIVE,addVideo);
}

function* removeVideo({video_sub_id}) {
    const data=yield deleteVideoInteractiveFromApplication(video_sub_id);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:REMOVE_VIDEO_SUCCESS_INTERACTIVE,
                video_sub_id:video_sub_id
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}

export  function* watchRemoveInteractiveVideo() {
    yield takeLatest(REMOVE_VIDEO_INTERACTIVE,removeVideo);
}


function* editVideo({video}) {
    const data=yield updateVideoInteractiveFromApplication(video);
    const responseJson=yield data.json();
    try {
        if(responseJson!=false){
            yield put({
                type:EDIT_VIDEO_SUCCESS_INTERACTIVE,
                video:video
            });
        }
    }catch(error){
        console.log("error "+error);
    }
}

export  function* watchEditInteractiveVideo() {
    yield takeLatest(EDIT_VIDEO_INTERACTIVE,editVideo);
}
