import React, { Component } from "react";
import SettingMenu from "../../Shared/SettingMenu";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AccountStreamTable from '../../../components/Table/AccountStreamTable'
class EmailTemplateAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Alert Email</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">Veltrix</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/#">Email Templates</Link>
                  </li>
                  <li className="breadcrumb-item active">Alert Email</li>
                </ol>
              </div>
            </Col>
          </div>

          <Row>
            <Col md={12}>
              <AccountStreamTable />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default EmailTemplateAlert;
