import constant from "../../constant";
export function getSubscribeOfVideo(channel_id){
    //console.log(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/getSubscribeOfVideoSub/"+channel_id);
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelSubscribeController/getSubscribeOfChannelSubscibe/"+channel_id, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
        //<-- Post parameters
    });
}
export function GetCurrentSubscribesOfVideo(channel_id,video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/getCurrentSubcribeOfChannel/"+this.channel_id, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "video_sub_id=" + video_sub_id + "&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getViewOfVideo(video_id){
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/VideoSubController/getViewOfVideoSub/"+video_id, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
        //<-- Post parameters
    });
}
