import React, {Component} from "react";

export default class Loading extends Component {
    constructor(prop) {
        super(prop);

    }

    render() {
        return (
            <div className="card-body">
                <div>
                    <div className="spinner-grow text-primary m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-info m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-warning m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-danger m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-dark m-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>);
    }
}
