import constant from "../../constant";
export function GetListChannelSubscribes(){
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelSubscribeController/getDatas/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
        //<-- Post parameters
    });
}
export function insertChannelSubscribe(channelsubscribe){
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelSubscribeController/insertData/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token')+
            "&channel_subscribe_id="+channelsubscribe.channel_subscribe_id+"&listvideo="+channelsubscribe.listvideo+
            "&sub_begin="+channelsubscribe.sub_begin+"&sub_per_day="+channelsubscribe.sub_per_day+
            "&sub_max="+channelsubscribe.sub_max+"&listvideo="+channelsubscribe.listvideo+
            "&video_browser_type="+channelsubscribe.video_browser_type+
            "&sub_split="+channelsubscribe.sub_split+"&allow="+channelsubscribe.allow+
            "&video_view_time="+channelsubscribe.video_view_time, // <-- Post parameters
        //<-- Post parameters
    });
}
export function updateChannelSubscribe(channelsubscribe){
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelSubscribeController/updateData/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token')+
            "&channel_subscribe_id="+channelsubscribe.channel_subscribe_id+"&listvideo="+channelsubscribe.listvideo+
            "&sub_begin="+channelsubscribe.sub_begin+"&sub_per_day="+channelsubscribe.sub_per_day+
            "&sub_max="+channelsubscribe.sub_max+"&listvideo="+channelsubscribe.listvideo+
            "&sub_split="+channelsubscribe.sub_split+"&allow="+channelsubscribe.allow+
            "&video_browser_type="+channelsubscribe.video_browser_type+
            "&video_view_time="+channelsubscribe.video_view_time, // <-- Post parameters
        //<-- Post parameters
    });
}

export function removeChannelSubscribe(channel_subscribe_id){
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ChannelSubscribeController/deleteData/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token')+"&channel_subscribe_id="+channel_subscribe_id, // <-- Post parameters
        //<-- Post parameters
    });
}

export function getSubscribeRunDataWeek(channel_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/SubBoosterController/getSubRunDataWeek/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"channel_id="+channel_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getSubscribeRunData24H(channel_id) {
    //console.log(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/ActionHistoryController/getSubscribeRunData24H/");
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/SubBoosterController/getSubRunData24H/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"channel_id="+channel_id +"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}

export function getListSubBoosterRunning() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/SubBoosterController/getListDataRunning/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function getListSubBoosterSuccess(video_sub_id) {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/SubBoosterController/getListDataSuccess/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body: "video_sub_id=" + video_sub_id + "&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
export function deleteSubBoosterFail() {
    return fetch(constant.protocal+"://" + constant.pageUrl + "/backend/index.php/SubBoosterController/deleteSubBoosterFail/", {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
        body:"&token=" + sessionStorage.getItem('token'), // <-- Post parameters
    });
}
