import constant from "../../constant";


/**
 * analytics
 */

export function userAnalytics() {
    return FetchUserDataApi('GET',"userAnalytics","");
}

/**
 * billes
 */
export function getBilles() {
    return FetchUserDataApi('GET',"billes","");
}
export function billUpdate(id) {
    let data="id="+id;
    return FetchUserDataApi('POST',"billUpdate",data);
}
export function billDeletes() {
    return FetchUserDataApi('GET',"billDeletes","");
}

/**
 * Product
 */
export function getProducts() {
    return FetchUserDataApi('GET',"products/","");
}
export function insertProductExpired(user_id,code,day) {
    let body="user_id="+user_id+"&code="+code+"&day="+day; // <-- Post parameters
    return FetchUserDataApi('POST',"ProductExpiredInsert",body);
}
export function deletePlaylistConfigCategory(id) {
    return FetchUserDataApi('GET',"categoryDelete/"+id,"");
}




/**
 * User
 */
export function getUsers() {
    return FetchUserDataApi('GET',"users/","");
}
export function getUserInfo(user_id) {
    return FetchUserDataApi('GET',"userinfo/"+user_id,"");
}
/**
 * Playlist config
 */
export function getListPlaylistConfig(category_id) {
    return FetchUserDataApi('GET',"configs/"+category_id,"");
}
export function insertPlaylistConfig(playlist) {
    const body="max_per_account="+playlist.max_per_account + "&max_per_day_account="+playlist.max_per_day_account
        +"&keyword_list="+playlist.keyword_list+"&title_list="+playlist.title_list+"&max="+playlist.max
        +"&max_video="+playlist.max_video+"&title_mix_type="+playlist.title_mix_type+"&video_mix_type="+playlist.video_mix_type
        +"&note="+playlist.note+"&status="+playlist.status+"&category_id="+playlist.category_id;
    return FetchUserDataApi('POST',"PlaylistConfigInsert",body);
}
export function updatePlaylistConfig(playlist) {
    const body="max_per_account="+playlist.max_per_account + "&max_per_day_account="+playlist.max_per_day_account
        +"&keyword_list="+playlist.keyword_list+"&title_list="+playlist.title_list
        +"&max_video="+playlist.max_video+"&title_mix_type="+playlist.title_mix_type+"&video_mix_type="+playlist.video_mix_type
        +"&note="+playlist.note+"&status="+playlist.status+"&max="+playlist.max+"&category_id="+playlist.category_id
        +"&id="+playlist.id;
    return FetchUserDataApi('POST',"PlaylistConfigUpdate",body);
}
export function deletePlaylistConfig(id) {
    return FetchUserDataApi('GET',"PlaylistConfigDelete/"+id,"");
}

/**
 * Playlists
 */
export function getPlaylists(limit,page,filterMode) {
    return FetchUserDataApi('GET',"playlists/"+filterMode+"/"+page+"/"+limit,"");
}
export function getPlaylistInfo(playlistId) {
    return FetchUserDataApi('GET',"playlist/"+playlistId,"");
}
export function updatePlayList(playlist) {
    let body="playlist_title="+playlist.playlistTitle + "&video_one="+playlist.videoOne
        +"&video_two="+playlist.videoTwo+"&video_three="+playlist.videoThree
        +"&playlist_id="+playlist.playlistId+"&need_delete="+playlist.need_delete; // <-- Post parameters
    return FetchUserDataApi('POST',"playlistUpdate",body);
}
export function removeError() {
    return FetchUserDataApi('GET',"removeError","");
}
export function refreshUpdating() {
    return FetchUserDataApi('GET',"refreshUpdating","");
}
export function scanAndCheck(playlistId) {
    return FetchUserDataApi('GET',"checkplaylist/"+playlistId,"");
}




/**
 * Account channel
 */
export function getAccounts(computername,filterMode,page,limit) {
    const search = filterMode==""?"all":filterMode;
    return FetchUserDataApi('GET',"accounts/"+computername+"/"+search+"/"+page+"/"+limit,"");
}
export function getComputers() {
    return FetchUserDataApi('GET',"computers","");
}
/**
 * @param method
 * @param urlParam
 * @param data
 * @returns {Promise<Response>}
 * @constructor
 */
function FetchUserDataApi(method,urlParam,data) {
    if(method=="GET"){
        return fetch(constant.protocal+"://" + constant.pageUrl + "/AdminDataApi/"+urlParam, {
            method: "GET",
            credentials: 'include',
            //mode:'cors',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                'Authorization': sessionStorage.getItem('token'),
                'content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials':true,
            }),
        });
    }else{
        return fetch(constant.protocal+"://" + constant.pageUrl + "/AdminDataApi/"+urlParam, {
            method: "POST",
            credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                'Authorization': sessionStorage.getItem('token'),
            }),
            body:data
        });
    }
}


