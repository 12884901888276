 export const ADD_VIDEO_INTERACTIVE="ADD_VIDEO_INTERACTIVE";
 export const ADD_VIDEO_SUCCESS_INTERACTIVE="ADD_VIDEO_SUCCESS_INTERACTIVE";
 export const REMOVE_VIDEO_INTERACTIVE="REMOVE_VIDEO_INTERACTIVE";
 export const EDIT_VIDEO_INTERACTIVE="EDIT_VIDEO_INTERACTIVE";
 export const FETCH_VIDEO_INTERACTIVE="FETCH_VIDEO_INTERACTIVE";
 export const FETCH_SUCCESS_INTERACTIVE="FETCH_SUCCESS_INTERACTIVE";
 export const FETCH_ERROR_INTERACTIVE="FETCH_ERROR_INTERACTIVE";
 export const FILTER_VIDEO_INTERACTIVE="FILTER_VIDEO_INTERACTIVE";
 export const FILTER_VIDEO_NOTE_INTERACTIVE="FILTER_VIDEO_NOTE_INTERACTIVE";
 export const REMOVE_VIDEO_SUCCESS_INTERACTIVE="REMOVE_VIDEO_SUCCESS_INTERACTIVE";
 export const EDIT_VIDEO_SUCCESS_INTERACTIVE="EDIT_VIDEO_SUCCESS_INTERACTIVE";
 export const FILTER_VIDEO_RUN_TYPE_INTERACTIVE="FILTER_VIDEO_RUN_TYPE_INTERACTIVE";
 export const FILTER_VIDEO_RUN_INTERACTIVE="FILTER_VIDEO_RUN_INTERACTIVE";
 export const FILTER_VIDEO_STATUS_INTERACTIVE="FILTER_VIDEO_STATUS_INTERACTIVE";


