import constant from "../../constant";

/**
 * Bill
 */


/**
 * Playlist config category
 */
export function getPlaylistConfigCategory() {
    return FetchUserDataApi('GET',"category/","");
}




export function insertPlaylistConfigCategory(category) {
    let body="title="+category.title; // <-- Post parameters
    return FetchUserDataApi('POST',"categoryInsert",body);
}
export function deletePlaylistConfigCategory(id) {
    return FetchUserDataApi('GET',"categoryDelete/"+id,"");
}


/**
 * Playlist config
 */
export function getListPlaylistConfig(category_id) {
    return FetchUserDataApi('GET',"configs/"+category_id,"");
}
export function insertPlaylistConfig(playlist) {
    const body="max_per_account="+playlist.max_per_account + "&max_per_day_account="+playlist.max_per_day_account
        +"&keyword_list="+playlist.keyword_list+"&title_list="+playlist.title_list+"&max="+playlist.max
        +"&max_video="+playlist.max_video+"&title_mix_type="+playlist.title_mix_type+"&video_mix_type="+playlist.video_mix_type
        +"&note="+playlist.note+"&status="+playlist.status+"&category_id="+playlist.category_id;
    return FetchUserDataApi('POST',"PlaylistConfigInsert",body);
}
export function updatePlaylistConfig(playlist) {
    const body="max_per_account="+playlist.max_per_account + "&max_per_day_account="+playlist.max_per_day_account
        +"&keyword_list="+playlist.keyword_list+"&title_list="+playlist.title_list
        +"&max_video="+playlist.max_video+"&title_mix_type="+playlist.title_mix_type+"&video_mix_type="+playlist.video_mix_type
        +"&note="+playlist.note+"&status="+playlist.status+"&max="+playlist.max+"&category_id="+playlist.category_id
        +"&id="+playlist.id;
    return FetchUserDataApi('POST',"PlaylistConfigUpdate",body);
}
export function deletePlaylistConfig(id) {
    return FetchUserDataApi('GET',"PlaylistConfigDelete/"+id,"");
}

/**
 * Playlists
 */
export function getPlaylists(limit,page,filterMode) {
    return FetchUserDataApi('GET',"playlists/"+filterMode+"/"+page+"/"+limit,"");
}
export function getPlaylistInfo(playlistId) {
    return FetchUserDataApi('GET',"playlist/"+playlistId,"");
}
export function updatePlayList(playlist) {
    let body="playlist_title="+playlist.playlistTitle + "&video_one="+playlist.videoOne
        +"&video_two="+playlist.videoTwo+"&video_three="+playlist.videoThree
        +"&playlist_id="+playlist.playlistId+"&need_delete="+playlist.need_delete; // <-- Post parameters
    return FetchUserDataApi('POST',"playlistUpdate",body);
}
export function removeError() {
    return FetchUserDataApi('GET',"removeError","");
}
export function refreshUpdating() {
    return FetchUserDataApi('GET',"refreshUpdating","");
}
export function scanAndCheck(playlistId) {
    return FetchUserDataApi('GET',"checkplaylist/"+playlistId,"");
}
/**
 * Account channel
 */
export function getAccounts() {
    return FetchStreamDataApi('GET',"accounts","");
}
export function insertAccount(modal) {
    let body="username="+modal.modal_username + "&channel_name="+modal.modal_channelName
        +"&channel_id="+modal.modal_channelId
    return FetchStreamDataApi('POST',"insertAccount",body);
}
export function updateAccount(channel_id,key_live) {
    let body="channel_id="+channel_id
    console.log(body)
    return FetchStreamDataApi('POST',"updateAccount",body);
}
export function removeAccount(channel_id) {
    return FetchStreamDataApi('GET',"removeAccount/"+channel_id,"");
}
export function getComputers() {
    return FetchStreamDataApi('GET',"computers","");
}
export function getStreams(channel_id) {
    return FetchStreamDataApi('GET',"streams/"+channel_id,"");
}
export function getClients() {
    return FetchStreamDataApi('GET',"clients/","");
}
export function clientDelete(name) {
    return FetchStreamDataApi('GET',"clientDelete/"+name,"");
}
export function getStreamsRunning() {
    return FetchStreamDataApi('GET',"streamsRunning","");
}
export function insertStream(modal) {
    let body="start_time="+modal.startTimeUnix + "&end_time="+modal.modal_endTime
        +"&list_video="+modal.modal_listVideo+"&channel_id="+modal.channel_id+"&full_hd="+modal.model_fullHD
        +"&key_live="+modal.modal_keylive+"&note="+modal.modal_note
    console.log(body)
    return FetchStreamDataApi('POST',"insertStream",body);
}
export function updateStream(modal) {
    let body="start_time="+modal.startTimeUnix + "&end_time="+modal.modal_endTime
        +"&list_video="+modal.modal_listVideo+"&stream_id="+modal.stream_id+"&full_hd="+modal.model_fullHD
        +"&key_live="+modal.modal_keylive+"&note="+modal.modal_note
    console.log(body)
    return FetchStreamDataApi('POST',"updateStream",body);
}
export function updateStreamStatus(status_number,status,stream_id) {
    let body="status_number="+status_number + "&status="+status+"&stream_id="+stream_id
    //console.log(body)
    return FetchStreamDataApi('POST',"updateStreamStatus",body);
}
export function removeStream(stream_id) {
    return FetchStreamDataApi('GET',"removeStream/"+stream_id,"");
}


/**
 * @param method
 * @param urlParam
 * @param data
 * @returns {Promise<Response>}
 * @constructor
 */
function FetchStreamDataApi(method,urlParam,data) {
    if(method=="GET"){
        console.log(sessionStorage.getItem('token'));
        const url=constant.protocal+"://" + constant.pageUrl + "/AccountStreamApi/"+urlParam;
        console.log(url)
        return fetch(url, {
            method: "GET",
            credentials: 'include',
            //mode:'cors',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                'Authorization': sessionStorage.getItem('token'),
                'content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials':true,
            }),
        });

    }else{
        const url=constant.protocal+"://" + constant.pageUrl + "/AccountStreamApi/"+urlParam
        console.log(url)
        console.log(sessionStorage.getItem('token'))
        return fetch(url, {
            method: "POST",
            credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                'Authorization': sessionStorage.getItem('token'),
            }),
            body:data
        });
    }
}
function FetchUserDataApi(method,urlParam,data) {
    if(method=="GET"){
        console.log(sessionStorage.getItem('token'));
        return fetch(constant.protocal+"://" + constant.pageUrl + "/UserDataApi/"+urlParam, {
            method: "GET",
            credentials: 'include',
            //mode:'cors',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                'Authorization': sessionStorage.getItem('token'),
                'content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials':true,
            }),
        });

    }else{
        return fetch(constant.protocal+"://" + constant.pageUrl + "/UserDataApi/"+urlParam, {
            method: "POST",
            credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                'Authorization': sessionStorage.getItem('token'),
            }),
            body:data
        });
    }
}


