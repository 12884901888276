import React, {Component} from "react";
import TablePlaylistConfig from "../../../components/Table/TablePlaylistConfig";
import TablePlaylistConfigCategory from "../../../components/Table/TablePlaylistConfigCategory";
import {
    Row,
    Col,
} from "reactstrap";
import PlayListTable from "../../../components/Table/PlayListTable";

class Playlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folder_menu: false,
            tag_menu: false,
            more_menu: false,
            category_id:"",
            video: {
                video_sub_id: '',
                channel_id: ''
            }
        };
        this.toggleFolder = this.toggleFolder.bind(this);
        this.toggleTag = this.toggleTag.bind(this);
        this.toggleMore = this.toggleMore.bind(this);
        this.chageModeView = this.chageModeView.bind(this);
        this.showPlaylistConfigViaCategory = this.showPlaylistConfigViaCategory.bind(this);


    }

    toggleFolder() {
        this.setState(prevState => ({
            folder_menu: !prevState.folder_menu
        }));
    }

    toggleTag() {
        this.setState(prevState => ({
            tag_menu: !prevState.tag_menu
        }));
    }

    toggleMore() {
        this.setState(prevState => ({
            more_menu: !prevState.more_menu
        }));
    }

    chageModeView(video) {
        //console.log(video);
        this.setState({
            video: video
        });
    }

    componentDidMount() {
    }

    showPlaylistConfigViaCategory(id){
        this.setState({
            category_id:id
        })
    }


    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4 className="font-size-18">Cấu hình playlist</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={5}>
                            <TablePlaylistConfigCategory parent={this} />
                        </Col>
                        <Col sm={7}>
                            <TablePlaylistConfig key={this.state.category_id} parent={this} category_id={this.state.category_id} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <PlayListTable key={this.state.video.video_sub_id} video={this.state.video}
                                                realtime={true} type={2}/>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Playlist;
