import React, {Component} from "react";
import {
    Card,
    CardBody,
    PaginationItem,
    PaginationLink,
    CardHeader,
    Form,
    Row,
    Col,
    Label,
    Input,
    Button, Modal
} from "reactstrap";
import {showChannels,searchChannel} from '../../helpers/ServiceUtils/DataService'
import Channel from '../../components/TableItem/Channel'
import Loading from "../Action/Loading";
class ChannelListTable extends Component{
    constructor(props) {
        super(props);
        this.state={
            data:[],
            pages:[],
            page:0,
            modal_info: false,
            currentChannelId:'',
            username:'',
            passwd:'',
            recover:'',
            phone:'',
            loading:true
        }
        this.showDetail = this.showDetail.bind(this);
    }
    showDetail(channel_id,username,passwd,recover,phone){
        this.setState( {
            currentChannelId:channel_id,
            username:username,
            passwd:passwd,
            recover:recover,
            phone:phone,
            modal_info: !this.state.modal_info
        });
    }
    componentDidMount() {
        this.showData(0);
    }

    showData = (page) => {
        this.setState({
            loading:true
        })
        showChannels(page)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== false) {
                    this.setState({
                        data: responseJson.channels,
                        pages: responseJson.pages,
                        page: page,
                        loading:false
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }
    showDataSearch = (page) => {
        this.setState({
            loading:true
        })
        //e.preventDefault();
        let params = "likeFilter=" + this.state.likeFilter + "&likeFilter2=" + this.state.likeFilter2 + "&view=" + this.state.view
           + "&subscribe=" + this.state.subscribe + "&token=" + sessionStorage.getItem('token');
        searchChannel(page,params)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== false) {
                    this.setState({
                        data: responseJson.channels,
                        pages: responseJson.pages,
                        page: page,
                        loading:false
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }

    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }

    render() {
        let stt=this.state.page*50;
        const  tableItem = this.state.data.map((item, index) => {
            return <Channel key={item.channel_id} stt={stt++}
                            onShow={()=>this.showDetail(item.channel_id,item.channel_username,item.channel_password,item.channel_recover,item.channel_phone)}
                            channel_id={item.channel_id} channel_title={item.channel_title}
                            channel_date={item.channel_date}
                            channel_view={item.channel_view}
                            channel_follow={item.channel_follow}
                            channel_recover={item.channel_recover}
                            channel_view_increase={item.channel_view_increase}
                            channel_videos={item.channel_videos} channel_videos_life={item.channel_videos_life}
                            channel_subscriber={item.channel_subscriber}
                            channel_spin_time={item.channel_spin_time}
                            channel_lastupdate_time={item.channel_lastupdate_time} reload={false}
                            channel_last_video={item.channel_last_video}
                            channel_subscriber_increase={item.channel_subscriber_increase}
                            channel_status={item.channel_status} channel_username={item.channel_username}
                            channel_password={item.channel_password} channel_phone={item.channel_phone}
                            channel_earnmoney={item.channel_earnmoney}
                            channel_threestep={item.channel_threestep}
                            showPass={this.state.showPass}
            />;

        });
        const pageItem = this.state.pages.map((item, index) => {
            if (this.state.page === item) {
                return <PaginationItem key={index} active>
                    <PaginationLink tag="button" onClick={() => this.showData(item)}>{item}</PaginationLink>
                </PaginationItem>;
            } else {
                return <PaginationItem key={index}>
                    <PaginationLink tag="button" onClick={() => this.showData(item)}>{item}</PaginationLink>
                </PaginationItem>;
            }
        });
        return (
            <Card>
                <CardHeader>
                    <Form onSubmit={()=>this.showDataSearch(0)}>
                        <div data-repeater-list="group-a">
                            <Row data-repeater-item>
                                <Col lg="3" className="form-group">
                                    <Label for="name">Channel ID/Channel Name</Label>
                                    <Input onChange={this.onHandleChange} type="text" id="name" name="likeFilter"/>
                                </Col>
                                <Col lg="3" className="form-group">
                                    <Label for="email">Email/Phone</Label>
                                    <Input onChange={this.onHandleChange}  type="email" id="likeFilter2" />
                                </Col>
                                <Col lg="1" className="form-group">
                                    <Label for="subject">View</Label>
                                    <Input onChange={this.onHandleChange}  type="text" id="subject" name="view"/>
                                </Col>
                                <Col lg="1" className="form-group">
                                    <Label for="subject">Subscribes</Label>
                                    <Input onChange={this.onHandleChange}  type="text" id="subject" name="subscribe"/>
                                </Col>
                                <Col lg="4" className="form-group align-self-center">
                                    <Button onClick={()=>this.showDataSearch(0)} color="primary" className="mt-3" style={{ width: "100%" }}>
                                            Tìm Kiếm
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Tên Kênh</th>
                                <th scope="col">Ngày Nhập</th>
                                <th scope="col">Lần cuối</th>
                                <th scope="col">Tiêu đề</th>
                                <th scope="col">Views</th>
                                <th scope="col">Videos</th>
                                <th scope="col">Subscribes</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.loading?<Loading />:tableItem}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">
                            {pageItem}
                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.modal_info}
                    toggle={this.showDetail}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{"Kênh : "+this.state.currentChannelId}</h5>
                        <button
                            type="button"
                            onClick={() =>
                                this.setState({ modal_center: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Username
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.username}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Passwd
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.passwd}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Recover
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.recover}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-2 col-form-label"
                            >
                                Phone
                            </label>
                            <Col sm={10}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.phone}
                                    id="example-text-input"
                                />
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Card>
        );
    }
}
export default ChannelListTable;
