import React, {Component} from "react";
import {Card, CardBody, Col, Form, Input, Row, Button, Modal} from 'reactstrap';
import {
    PaginationItem,
    PaginationLink
} from "reactstrap";
// service
import {getUsers,getUserInfo,getProducts,insertProductExpired} from '../../helpers/ServiceUtils/AdminDataApi'
import User from "../TableItem/User";
import CardHeader from "reactstrap/es/CardHeader";
import moment from "moment";
class TableUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page:0,
            data:[],
            dataMax:[],
            startPage:0,
            maxrow:50,
            endPage:0,
            total:0,
            product_expireds:[],
            modal_info:false,
            user_id:'',
            products:[],
            productsMax:[],
            productExpiredDay:7,
            addProductModel:false,
            ProductExpiredCode:"",
        }
        this.showInsert=this.showInsert.bind(this);
        this.showDetail=this.showDetail.bind(this);
        this.togProductModel=this.togProductModel.bind(this);
        this.insertProductExpired=this.insertProductExpired.bind(this);

    }
    componentDidMount() {
        this.showData(0,this.state.fromDate,this.state.toDate);
    }
    showDetail() {
        this.setState({
            modal_info: !this.state.modal_info
        })
    }
    togProductModel() {
        this.setState({
            addProductModel: !this.state.addProductModel
        })
    }



    addProduct(user_id){
        getProducts()
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        products:responseJson.products,
                        productsMax:responseJson.products,
                        addProductModel: !this.state.addProductModel,
                        user_id:user_id
                    });
                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }

    insertProductExpired(){
        insertProductExpired(this.state.user_id,this.state.ProductExpiredCode,this.state.productExpiredDay)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        addProductModel: !this.state.addProductModel,
                    });
                }
            })
            .catch((error) => {
                console.log("error " + error);
            });

    }


    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
        if(name=='fromDate'){
            this.showData(0,value,this.state.toDate);
        }else{
            this.showData(0,this.state.fromDate,value);
        }
    }
    onHandleChangeSearch = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
        const newData = this.state.dataMax.filter((item)=>{
            if(item.username.includes(value)){
                return true;
            }
            return false;
        })
        this.setState({
            data:newData
        })
    }
    showData(page,from,to) {
        getUsers(page, this.props.maxrow, this.props.tag,from,to)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        page: page,
                        data: responseJson.users,
                        dataMax: responseJson.users,
                        startPage:responseJson.startPage,
                        endPage:responseJson.endPage,
                        total:responseJson.total
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }
    showInsert() {
        this.setState({
            insertMode: true,
            modal_info: !this.state.modal_info,
            max_per_account :5,
            max_per_day_account: 20,
            description: '',
            title_list: '',
            max_video : 10,
            video_mix_type:0,
            title_mix_type:0,
            category_id:"",
            note :'',
            status :1,
            max:100
        })
    }



    render() {
        let stt = 1;
        const pages=[];
        const {startPage,endPage,page}=this.state;
        for (let i = startPage; i <endPage; i++){
            pages[i]=i;
        }
        const pageRow =pages.map(({item,index})=>{
            if (item==page) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => this.getData(this.state.limit,index,this.state.filterMode)  }>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => this.getData(this.state.limit,index,this.state.filterMode)  }>{index}</PaginationLink></PaginationItem>;
            }
        });
        const tableProductExpired=this.state.product_expireds.map((item,index)=>{
            return (<tr style={{height: 5}}>
                        <td>{index}</td>
                        <td>{item.name}</td>
                        <td>{moment(item.expired_time,"x").diff(moment(), "days") +" days"  }</td>
                    </tr>);
        });
        const productOptions=this.state.products.map((item,index)=>{
            return <option value={item.code}>{item.name}</option>;
        });
        const dataTable = this.state.data.map((item, index) => {
            return <User key={this.props.tag + "-" + item.id}
                                stt={this.state.page * this.state.maxrow + stt++}
                                id={item.id}
                                username={item.username}
                                email={item.email}
                                phone={item.phone}
                                status={item.status}
                                money={item.money}
                                showUserInfo={()=>this.props.parent.showUserinfo(item.id)}
                                addProduct={()=>this.addProduct(item.id)}
            />;
        });
        return (
            <Card>
                <CardHeader>
                    <div>
                        <Row>
                                <Col sm={10}>
                                    <input
                                        onChange={this.onHandleChangeSearch}
                                        className="form-control"
                                        type="text"
                                        placeholder={'Tìm kiếm tài khoản ...'}
                                        name={'userSearch'}
                                        defaultValue={''}
                                        id="example-date-input"
                                    />
                                </Col>
                        </Row>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">NAME</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">PHONE</th>
                                <th scope="col">ACTED</th>
                                <th scope="col">$$</th>
                                <th scope="col">---</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataTable}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">
                            {pageRow}
                        </ul>
                    </div>
                </CardBody>


                <Modal
                    isOpen={this.state.modal_info}
                    className="modal-lg"
                    toggle={this.showDetail}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">USER INFO</h5>
                        <button
                            type="button"
                            onClick={this.showDetail}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                NAME
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    defaultValue={this.state.name}
                                    onChange={this.onHandleChange}
                                    id="name"
                                />
                            </Col>
                        </Row>
                        {"LIST PRODUCT USING"}
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">PRODUCT</th>
                                <th scope="col">TIME LEFT</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tableProductExpired}
                            </tbody>
                        </table>
                        <Row className="align-items-center">
                            <Col sm={2}>
                                <Button onClick={this.insertOrUpdateData} color={'primary'}>SUBMIT</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.addProductModel}
                    className="modal-sm"
                    toggle={this.togProductModel}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">ADD PRODUCT FOR USER</h5>
                        <button
                            type="button"
                            onClick={this.togProductModel}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                DAYS
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="productExpiredDay"
                                    defaultValue={this.state.productExpiredDay}
                                    onChange={this.onHandleChange}
                                    id="productExpiredDay"
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                PRODUCT
                            </label>
                            <Col sm={9}>
                                <select className="form-control"
                                        name={'ProductExpiredCode'}
                                        onChange={this.onHandleChange}
                                        defaultValue={this.state.ProductExpiredCode}>
                                        {productOptions}
                                </select>
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Col sm={2}>
                                <Button onClick={this.insertProductExpired} color={'primary'}>SUBMIT</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>



            </Card>
        );
    }

}

export default TableUser;
