import React,{Component} from "react";
import {Button} from "reactstrap";
import {getListChannelIn24h, getVideoViewsOfChannel} from '../../helpers/ServiceUtils/DataService'
class ReloadView24h extends Component{
    constructor(props) {
        super(props);
        this.state={
            refreshing: false,
            dataChannel24h:[],
            currentReload:0
        }
        this.reloadViewVideo = this.reloadViewVideo.bind(this);
    }
    reloadViewVideo() {
        if (this.state.refreshing) {
            return;
        }
        this.setState({
            refreshing: true,
            currentReload:0
        });
        getListChannelIn24h()
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    dataChannel24h:responseJson.channels
                });
                this.state.dataChannel24h.map((item,index)=>{
                    this.refreshViewOfChannel(item);
                })
            }).catch(error => {
        });
    }
    refreshViewOfChannel(channel_id) {
        getVideoViewsOfChannel(channel_id)
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    currentReload:this.state.currentReload+1
                });
                if(this.state.currentReload==this.state.dataChannel24h.length){
                    this.setState({
                        refreshing: false
                    })
                }
            }).catch(error => {
            this.setState({
                currentReload:this.state.currentReload+1
            });
            if(this.state.currentReload==this.state.dataChannel24h.length){
                this.setState({
                    refreshing: false
                })
            }
        });
    }
    render() {
        return (
            <Button sm={4} color={'primary'}
                    onClick={this.reloadViewVideo}> {this.state.refreshing ? "Reloading "+this.state.currentReload+"/"+this.state.dataChannel24h.length+" kênh" : "Reload View"}</Button>
        );
    }
}

export default ReloadView24h;
