import React, {Component} from "react";
import {Card, CardBody, Col, Form, Input, Row, Button, Modal,Label} from 'reactstrap';
import {getStreamsRunning} from '../../store/Playlist/ServiceApi'
import StreamingRow from "../TableItem/StreamingRow";
import CardHeader from "reactstrap/es/CardHeader";
import Loading from "../Action/Loading";
class ChannelStreamingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            data: [],
            dataMax: [],
            startPage: 0,
            maxrow: 50,
            endPage: 0,
            total: 0,
            streams: [],
            user_id: '',
            stream_id:'',
            channel_id:this.props.channel_id,
            productExpiredDay: 7,
            addProductModel: false,
            ProductExpiredCode: "",
            showModal:false,
            modal_keylive:"",
            loading:false,
            modal_note:"",
            modal_startTime:"09:30 PM",
            modal_endTime:"09:30 PM",
            modal_listVideo:"",
            reloadInterval:null,
            timeleft:30
        }
        this.loadStreams = this.loadStreams.bind(this);
        this.togShowModal = this.togShowModal.bind(this);
        this.showCurrentStream=this.showCurrentStream.bind(this)
    }
    componentDidMount() {
        this.loadStreams();
        this.setState({
            reloadInterval: setInterval(()=>{
                if(this.state.timeleft==0){
                    this.loadStreams()
                }else{
                    this.setState({
                        timeleft:this.state.timeleft-1
                    })
                }
            }, 1000)
        })
    }
    componentWillUnmount() {
        clearInterval(this.state.reloadInterval)
    }
    togShowModal(){
        if(!this.state.showModal){
            if(this.props.channel_id===""){
                alert("Chọn kênh trước khi thêm");
                return;
            }
            this.setState( {
                showModal: !this.state.showModal,
            });
        }else{
            this.setState( {
                showModal: !this.state.showModal,
                stream_id:'',
                modal_startTime:"09:30 PM",
                modal_endTime:"09:30 PM",
                modal_listVideo:""
            });
        }
    }

    loadStreams() {
        this.setState( {
            loading: true,
        });
        getStreamsRunning()
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        streams: responseJson.streams,
                        showModal: false,
                        modal_startTime:"09:30 PM",
                        modal_endTime:"09:30 PM",
                        modal_listVideo:"",
                    });
                }
            })
            .catch((error) => {
                console.log("error " + error);
            }).finally(()=>{
                this.setState({
                    timeleft:30,
                    loading:false
                });
            });
    }

    showCurrentStream(stream_id,start_time,end_time,list_video,key_live,note){
        this.setState({
            stream_id:stream_id,
            showModal: true,
            modal_startTime:start_time,
            modal_endTime:end_time,
            modal_listVideo:list_video,
            modal_keylive:key_live,
            modal_note:note
        });
    }

    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
        });
    }

    render() {
        const dataTable = this.state.streams.map((item, index) => {
            return <StreamingRow key={this.props.channel_id + "-" + item.stream_id}
                         stt={this.state.page * this.state.maxrow + (index + 1)}
                         stream_id={item.stream_id}
                         start_time={item.start_time}
                         end_time={item.end_time}
                         current_value={item.current_value}
                         list_video={item.list_video}
                         start_stream_time={item.start_stream_time}
                         status={item.status}
                         status_number={item.status_number}
                         computer_name={item.computer_name}
                         note={item.note}
                         key_live={item.key_live}
                         loadStreams={this.loadStreams}
                         showCurrentStream={()=>this.showCurrentStream(item.stream_id,item.start_time,item.end_time,item.list_video,item.key_live,item.note)}
            />;
        });
        return (
            <Card>
                <CardHeader>
                        <Row className="form-group">
                             <Button onClick={() =>this.loadStreams()} size={'sm'} color={'primary'}>{"Reload ngay hoặc tự động sau "+this.state.timeleft}</Button>
                        </Row>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Ghi Chú</th>
                                <th scope="col">Máy Đang Live</th>
                                <th scope="col">Start</th>
                                <th scope="col">End dự kiến</th>
                                <th scope="col">Thông số</th>
                                <th scope="col">Key Live</th>
                                <th scope="col">Trạng thái</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.loading?<Loading />:dataTable}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">

                        </ul>
                    </div>
                </CardBody>
            </Card>
        );
    }

}

export default ChannelStreamingTable;
