import React from "react"
import {Badge, Button} from 'reactstrap';
import {actionRemoveChannelSubscribe} from '../../store/channelsub/ChannelSubscribeAction';
import {connect} from 'react-redux';
import {getSubscribeOfVideo} from '../../helpers/ServiceUtils/ActionService';
class VideoSub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primary: false,
            removeMode: false,
            reload: this.props.reload,
            video_sub_id: this.props.video_sub_id,
            video_sub_day_completed: this.props.video_sub_day_completed,
            video_sub_max: this.props.video_sub_max,
            video_sub_per_day: this.props.video_sub_per_day,
            sub_completed: this.props.sub_completed,
            video_sub_running: this.props.video_sub_running,
            video_sub_view_time: this.props.video_sub_view_time,
            video_start_sub: this.props.video_start_sub,
            channel_id: this.props.channel_id,
            current_sub:0,
            video_sub_unique: this.props.video_sub_unique,

            loading: false,
        }
        this.removeVideo=this.removeVideo.bind(this);
        this.getCurrentSub=this.getCurrentSub.bind(this);
    }
    componentDidMount() {
        this.getCurrentSub();
    }
    getCurrentSub =()=>{
            this.setState({
                current_sub:-1
            });
            getSubscribeOfVideo(this.props.channel_subscribe_id)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson != false) {
                        this.setState({
                            current_sub:responseJson
                        })
                    }
                }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
            insertMessage: ""
        });
    }
    removeVideo = () => {
        if (window.confirm('Bạn chắc chắn xóa kênh này ?')) {
            this.props.actionRemoveChannelSubscribe(this.props.channel_subscribe_id);
        }
    }
    render() {
        console.log(this.props.video_browser_type);
        if (!this.state.removeMode) {
            return <tr onClick={()=>this.props.handleChangeMode()}>
                <td>{this.props.stt}</td>
                <td><a href={'https://www.youtube.com/channel/' + this.props.channel_subscribe_id}>{this.props.channel_subscribe_id}</a>{this.props.allow==1?<i
                    className="ti-check"></i>:null}</td>
                <td>{this.props.sub_begin+" + "+(this.state.current_sub-this.props.sub_begin)}</td>
                <td>{(this.props.sub_completed=="undefined"?0:this.props.sub_completed)+"/"+this.props.sub_max}</td>
                <td>{this.props.sub_day_completed +"/"+this.props.sub_per_day}</td>
                <td>{this.props.sub_hour_completed +"/"+ Math.ceil(this.props.sub_per_day/this.props.sub_split) }</td>
                <td><Badge size={'sm'} color={'secondary'} style={{fontSize:16,color:'#7ed6df'}}>{this.props.video_running}</Badge></td>
                <td>{this.props.video_browser_type==0?"Browser PC":"Apple Tv"}</td>
                <td>{this.state.video_sub_unique}</td>
                <td>{this.props.video_view_time}</td>
                <td>
                    <Button  onClick={this.props.editHandle} size={'sm'} color={'success'}>Sửa</Button>{' '}
                    <Button  onClick={this.removeVideo} size={'sm'} color={'danger'}>Xóa</Button>{' '}
                    <Button  onClick={this.getCurrentSub} size={'sm'} color={'secondary'}>Refresh</Button>
                </td>
            </tr>;
        } else {
            return null;
        }
    }
}
export default connect(null,{actionRemoveChannelSubscribe})(VideoSub);
