import React, {Component} from "react";
import {Card, CardBody, Col, Form, Input, Row, Button, Modal,Label} from 'reactstrap';
import {getStreams,insertStream,updateStream,updateAccount} from '../../store/Playlist/ServiceApi'
import User from "../TableItem/User";
import CardHeader from "reactstrap/es/CardHeader";
import moment from 'moment'
class ChannelStreamTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            data: [],
            dataMax: [],
            startPage: 0,
            maxrow: 50,
            endPage: 0,
            total: 0,
            streams: [],
            user_id: '',
            stream_id:'',
            channel_id:this.props.channel_id,
            productExpiredDay: 7,
            addProductModel: false,
            ProductExpiredCode: "",
            showModal:false,
            modal_keylive:"",
            modal_note:"",
            modal_startTime:moment().format('HH:mm:ss'),
            modal_startDate:moment().format('YYYY-MM-DD'),
            modal_minuteFrom:"",
            model_fullHD:0,
            modal_endTime:"0",
            modal_listVideo:"",
            startTimeUnix:0,
        }
        this.addStream = this.addStream.bind(this);
        this.loadStreams = this.loadStreams.bind(this);
        this.togShowModal = this.togShowModal.bind(this);
        this.showCurrentStream=this.showCurrentStream.bind(this)
    }
    componentDidMount() {
        this.loadStreams();
    }



    togShowModal(){
        if(!this.state.showModal){
            if(this.props.channel_id===""){
                alert("Chọn kênh trước khi thêm");
                return;
            }
            this.setState( {
                showModal: !this.state.showModal,
            });
        }else{
            this.setState( {
                showModal: !this.state.showModal,
                stream_id:'',
                modal_startTime:moment().format('HH:mm:ss'),
                modal_startDate:moment().format('YYYY-MM-DD'),
                modal_minuteFrom:"",
                model_fullHD:0,
                startTimeUnix:0,
                modal_endTime:"0",
                modal_listVideo:""
            });
        }
    }

    loadStreams() {
        getStreams(this.props.channel_id)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        streams: responseJson.streams,
                        showModal: false,
                        modal_startTime:moment().format('HH:mm:ss'),
                        modal_startDate:moment().format('YYYY-MM-DD'),
                        modal_minuteFrom:"",
                        modal_endTime:"0",
                        model_fullHD:0,
                        startTimeUnix:0,
                        modal_listVideo:""
                    });
                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }

    addStream() {
        if(this.state.stream_id===""){
            insertStream(this.state)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.loadStreams();
                    }else{
                        alert("Không thể thêm luồng này : Check lại keylive")
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }else{
            updateStream(this.state)
                .then((response) => {
                    console.log(response)
                    return response.json()
                })
                .then((responseJson) => {
                    if (responseJson.status == "success") {
                        this.loadStreams();
                    }
                })
                .catch((error) => {
                    console.log("error " + error);
                });
        }
    }
    showCurrentStream(stream_id,start_time,end_time,list_video,key_live,note,full_hd){
        this.setState({
            stream_id:stream_id,
            showModal: true,
            modal_startTime:moment.unix(start_time).format("HH:mm:ss"),
            modal_startDate:moment.unix(start_time).format("YYYY-MM-DD"),
            startTimeUnix:start_time,
            modal_endTime:end_time,
            modal_listVideo:list_video,
            modal_keylive:key_live,
            modal_note:note,
            model_fullHD:full_hd
        });
    }

    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value
        });
    }

    onHandleChangeTime = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
            startTimeUnix:moment(value+" "+this.state.modal_startDate,"HH:mm:ss YYYY-MM-DD").unix(),
            modal_minuteFrom:""
        });
    }

    onHandleChangeDate = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
            startTimeUnix:moment(this.state.modal_startTime+" "+value,"HH:mm:ss YYYY-MM-DD").unix(),
            modal_minuteFrom:""
        });
    }



    onHandleChangeMinute = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            modal_startTime:moment().add(value, 'minutes').format("HH:mm:ss"),
            modal_startDate:moment().add(value, 'minutes').format("YYYY-MM-DD"),
            startTimeUnix:moment().add(value, 'minutes').unix(),
            modal_minuteFrom:value,
        });
    }
    render() {

        const dataTable = this.state.streams.map((item, index) => {
            console.log(item)
            return <User key={this.props.channel_id + "-" + item.stream_id}
                         stt={this.state.page * this.state.maxrow + (index + 1)}
                         stream_id={item.stream_id}
                         start_time={item.start_time}
                         end_time={item.end_time}
                         list_video={item.list_video}
                         full_hd={item.full_hd}
                         status={item.status}
                         status_number={item.status_number}
                         note={item.note}
                         key_live={item.key_live}
                         loadStreams={this.loadStreams}
                         showCurrentStream={()=>this.showCurrentStream(item.stream_id,item.start_time,item.end_time,item.list_video,item.key_live,item.note,item.full_hd)}
            />;
        });
        return (
            <Card>
                <CardHeader>
                        <Row className="form-group">
                             <Button onClick={() =>this.togShowModal()} size={'sm'} color={'primary'}>Thêm Luồng</Button>&nbsp;&nbsp;
                             <Label>{this.props.channel_id===""?"Vui lòng chọn 1 tài khoản":"Bạn đã chọn tài khoản "+this.props.channel_id}</Label>
                        </Row>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Ghi Chú</th>
                                <th scope="col">Hẹn giờ</th>
                                <th scope="col">THời gian stream</th>
                                <th scope="col">Key Live</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {dataTable}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">

                        </ul>
                    </div>
                </CardBody>
                <Modal
                    isOpen={this.state.showModal}
                    toggle={this.togShowModal}
                    className={"modal-lg"}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">{"Kênh : "+this.props.channel_id}</h5>
                        <button
                            type="button"
                            onClick={() =>this.togShowModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Ghi Chú
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.modal_note}
                                    id="modal_note"
                                    onChange={this.onHandleChange}
                                    name={"modal_note"}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Key live
                            </label>
                            <Col sm={9}>
                                <input
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.modal_keylive}
                                    id="modal_keylive"
                                    onChange={this.onHandleChange}
                                    name={"modal_keylive"}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Danh sách videos
                            </label>
                            <Col sm={9}>
                                <textarea
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.state.modal_listVideo}
                                    id="modal_listVideo"
                                    onChange={this.onHandleChange}
                                    name={"modal_listVideo"}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                               Upload Full HD
                            </label>
                            <Col sm={9}>
                                <input
                                    id="model_fullHD"
                                    type="text"
                                    className="form-control"
                                    name={'model_fullHD'}
                                    value={this.state.model_fullHD}
                                    onChange={this.onHandleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                Thời gian bắt đầu
                            </label>
                            <Col sm={6}>
                                <Input
                                    id="modal_startTime"
                                    label="Alarm clock"
                                    type="time"
                                    name={'modal_startTime'}
                                    value={this.state.modal_startTime}
                                    onChange={this.onHandleChangeTime}
                                />
                                <input
                                    id="modal_startDate"
                                    type="date"
                                    name={'modal_startDate'}
                                    className="form-control"
                                    value={this.state.modal_startDate}
                                    onChange={this.onHandleChangeDate}
                                />
                            </Col>
                            <Col sm={3}>
                                <Input
                                    id="modal_minuteFrom"
                                    label="Alarm clock"
                                    type="text"
                                    placeHolder={"Stream sau (Phút)"}
                                    name={'modal_minuteFrom'}
                                    value={this.state.modal_minuteFrom}
                                    onChange={this.onHandleChangeMinute}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                            >
                                THời lượng sẽ live
                            </label>
                            <Col sm={9}>
                                <Input
                                    id="modal_endTime"
                                    label="Alarm clock"
                                    type="text"
                                    name={'modal_endTime'}
                                    defaultValue={this.state.modal_endTime}
                                    onChange={this.onHandleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="form-group">
                            <Button onClick={()=>this.addStream()} color="primary" size={'sm'}>
                                Lưu
                            </Button>
                        </Row>
                    </div>
                </Modal>
            </Card>

        );
    }

}

export default ChannelStreamTable;
