import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class barchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    events: {
                        selection: function(chartContext, { xaxis, yaxis }) {
                            console.log(chartContext)
                        }
                    }
                },
                colors: ['#3c4ccf', '#02a499'],
                plotOptions: {
                    bar: {
                        columnWidth: '70%',
                        dataLabels: {
                            show: false
                        },

                    },
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: true,
                },
                grid: {
                    borderColor: '#f8f8fa',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: this.props.categories,
                    axisBorder: {
                        show: true
                    },
                    axisTicks: {
                        show: true
                    },

                }
            },
            data: this.props.series
        }
    }
    render() {
        const series=[{
            name: 'Series A',
            data: this.state.data
        }];
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={series} type="bar" height="290" />
            </React.Fragment>
        );
    }
}

export default barchart;
