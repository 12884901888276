import React, {Component} from "react";
import {Card, CardBody, Col, Form, Input, Row, Button, Modal,Label} from 'reactstrap';
import {getClients} from '../../store/Playlist/ServiceApi'
import Client from "../TableItem/Client";
import CardHeader from "reactstrap/es/CardHeader";
import Loading from "../Action/Loading";

class ClientTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            reloadInterval:null,
            timeleft:30,
            loading:true
        }
        this.loadStreams = this.loadStreams.bind(this);
    }
    componentWillUnmount() {
        clearInterval(this.state.reloadInterval)
    }
    componentDidMount() {
        this.loadStreams();
        this.setState({
            reloadInterval: setInterval(()=>{
                if(this.state.timeleft==0){
                    this.loadStreams()
                }else{
                    this.setState({
                        timeleft:this.state.timeleft-1
                    })
                }
            }, 1000)
        })
    }
    loadStreams() {
        this.setState( {
            loading: true,
        });
        getClients()
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status == "success") {
                    this.setState({
                        clients: responseJson.clients,
                    });
                }
            })
            .catch((error) => {
                console.log("error " + error);
            }).finally(()=>{
            this.setState({
                timeleft:30,
                loading:false
            });
        });
    }
    render() {
        const dataTable = this.state.clients.map((item, index) => {
            return <Client key={item.id}
                           stt={index + 1}
                           id={item.id}
                           name={item.name}
                           last_time={item.last_time}
                           count={item.count}
                           loadStreams={this.loadStreams}

            />;
        });
        return (
            <Card>
                <CardHeader>
                        <Row className="form-group">
                             Danh sách máy đang hoạt động {this.state.timeleft}
                        </Row>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col">Tên</th>
                                <th scope="col">Lần hoạt động cuối</th>
                                <th scope="col">Số video đang live</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.loading?<Loading />:dataTable}
                            </tbody>
                        </table>
                        <ul data-test="pagination" className="pagination">

                        </ul>
                    </div>
                </CardBody>
            </Card>
        );
    }

}

export default ClientTable;
