import React, {Component} from "react";
import {Card, CardBody, Form, Input} from 'reactstrap';
import {
    PaginationItem,
    PaginationLink
} from "reactstrap";
import VideoUploadReport from '../../components/TableItem/VideoUploadReport';
// service
import {getVideoGroupData} from '../../helpers/ServiceUtils/DataService'
class GroupVideoUploadTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataGroup: [],
            dataGroupFilter: [],
            reportPAge:0,
            search:''
        };

    }



    componentDidMount() {
        this.VideoGroupData(0);
    }

    VideoGroupData(page) {
        getVideoGroupData(page)
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== false) {
                    this.setState({
                        dataGroup: responseJson,
                        dataGroupFilter:responseJson,
                        reportPAge:page
                    });
                } else {

                }
            })
            .catch((error) => {
                console.log("error " + error);
            });
    }
    onHandleChange = (event) => {
        var target = event.target;
        var name = target.name;
        var value = target.type == "checkbox" ? (target.checked ? 1 : 0) : target.value;
        this.setState({
            [name]: value,
            dataGroupFilter:this.state.dataGroup.filter((item, index) => {
                return item.video_upload_type_child.toLowerCase().includes(value.toLowerCase());
            })
        });
    }
    render() {
        const numerReportPage = this.state.dataGroupFilter.length/10;
        let mapReportPage= [];
        for(let i =0;i<numerReportPage;i++){
            mapReportPage[i]=i;
        }
        const dataReportPage = mapReportPage.map((item, index) => {
            if (this.state.reportPAge === index) {
                return <PaginationItem key={index} data-test="page-item" className="page-item active"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {this.setState({reportPAge:index})}}>{index}</PaginationLink></PaginationItem>
            } else {
                return <PaginationItem key={index} data-test="page-item" className="page-item"> <PaginationLink
                    data-test="page-link" className="page-link"
                    onClick={() => {this.setState({reportPAge:index})}}>{index}</PaginationLink></PaginationItem>;
            }
        });
        let sttReport=this.state.reportPAge*10;
        const dataGroup =  this.state.dataGroupFilter.slice(this.state.reportPAge*10, (this.state.reportPAge+1)*10 ).map((item, index) => {
            sttReport++;
            //console.log(sttReport);
            return <VideoUploadReport key={this.state.search+"-"+sttReport}
                                      stt={sttReport}
                                      video_upload_type_child={item.video_upload_type_child}
                                      channels_count={item.countChannel} total_view={item.totalView}
                                      onClick={()=>{
                                          this.props.parent.changeVideoFilder(item.video_upload_type_child);
                                      }}
            />;
        });
        return (
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Gom Nhóm</h4>
                    <Form onSubmit={this.onsubmitSearch}>
                        <input
                            onChange={this.onHandleChange}
                            className="form-control"
                            type="text"
                            name="search"
                            placeholder='Tìm kiếm hagtag ...'
                            id="example-text-input"
                        />
                    </Form>
                    <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">#</th>
                            <th scope="col">Video Chờ</th>
                            <th scope="col">Video Upload</th>
                            <th scope="col">Kênh</th>
                            <th scope="col">View</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataGroup}
                        </tbody>
                    </table>
                    <div className="float-right ">
                        <ul data-test="pagination" className="pagination" >
                            {dataReportPage}
                        </ul>
                    </div>
                </CardBody>
            </Card>
        );
    }


}
export default GroupVideoUploadTable;
